import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel'
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import Invoice from './components/Invoice';
import { useReactToPrint } from 'react-to-print';
import { getCOOReceipts, getSearchMember, registerCOO } from '../../../../../apis/api';
import { Toast } from "primereact/toast";
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { QueryGenerator } from '../../../../../helper/QueryGenerator';
import { Dialog } from 'primereact/dialog';
import DownloadReports from './DownloadReports';
import Select from 'react-select';

export const Reports = () => {
    const[compony,setCompony] = useState("");
    const [checked, setChecked] = useState(false);
    const [checked2, setChecked2] = useState(false);

    const [memberType, setMemberType] = useState("");
    const [payType, setPayType] = useState("regularReport");
    const [search, setSearch] = useState("");
    const [extraDetails, setExtraDetails] = useState(null);

    const [data,setData] = useState([])
    const [printData,setPrintData] = useState([])
    console.log(printData, 'gfdfghjk')
const[reportData, setReportData] = useState()
    const [invoiceData,setInvoiceData] = useState([])
    const[isLoading,setLoading] = useState(false)
    const[displaydata, setDisplayData] = useState(false)
    let count=0;

    const invoiceRef = useRef(null)
    const invoiceRef2 = useRef(null)
    const excelRef = useRef(null)
    const excelRef2 = useRef(null)
    const excelRef3 = useRef(null)
    const userType = window.localStorage.getItem("userType");

    const toast = useRef(null)

    const totals = {
      cash: {
        amount: 0,
        igst: 0,
        sgst: 0,
        cgst: 0,
        round_off_amount: 0,
        total_amount: 0,
        tatkal_amount: 0,
      },
      account: {
        amount: 0,
        igst: 0,
        sgst: 0,
        cgst: 0,
        round_off_amount: 0,
        total_amount: 0,
        tatkal_amount: 0,
      },
      overAll: {
        amount: 0,
        igst: 0,
        sgst: 0,
        cgst: 0,
        round_off_amount: 0,
        total_amount: 0,
        tatkal_amount: 0,
      },
    };
    const cashData = [];
    const accountData = [];
    printData.forEach((item) => {
      if (item.pay_mode === "Cash") {
        cashData.push(item);
      } else if (item.pay_mode === "Account") {
        accountData.push(item);
      }
    if(!item.cancel_status) { if (item.pay_mode === "Cash") {
        totals.cash.amount += parseFloat(item.amount) || 0;
        totals.cash.igst += parseFloat(item.igst) || 0;
        totals.cash.sgst += parseFloat(item.sgst) || 0;
        totals.cash.cgst += parseFloat(item.cgst) || 0;
        totals.cash.round_off_amount += parseFloat(item.round_off_amount) || 0;
        totals.cash.total_amount += parseFloat(item.total_amount) || 0;
        if (item.tatkal_amount !== null) {
          totals.cash.tatkal_amount += parseFloat(item.tatkal_amount) || 0;
        }
      }
      if (item.pay_mode === "Account") {
        totals.account.amount += parseFloat(item.amount) || 0;
        totals.account.igst += parseFloat(item.igst) || 0;
        totals.account.sgst += parseFloat(item.sgst) || 0;
        totals.account.cgst += parseFloat(item.cgst) || 0;
        totals.account.round_off_amount += parseFloat(item.round_off_amount) || 0;
        totals.account.total_amount += parseFloat(item.total_amount) || 0;
        if (item.tatkal_amount !== null) {
          totals.account.tatkal_amount += parseFloat(item.tatkal_amount) || 0;
        }
      }
      totals.overAll.amount += parseFloat(item.amount) || 0;
      totals.overAll.igst += parseFloat(item.igst) || 0;
      totals.overAll.sgst += parseFloat(item.sgst) || 0;
      totals.overAll.cgst += parseFloat(item.cgst) || 0;
      totals.overAll.round_off_amount += parseFloat(item.round_off_amount) || 0;
      totals.overAll.total_amount += parseFloat(item.total_amount) || 0;
      if (item.tatkal_amount !== null) {
        totals.overAll.tatkal_amount += parseFloat(item.tatkal_amount) || 0;
      }}
    });
    

   const  payOptions=[
        "Cash","Account","All"
      ]
      const  sortOptions=[
        { name: "GST No.", code: 'gst' },
        { name: 'Receipt No.', code: 'receiptNo' },
        { name: 'Compony Name', code: 'nameOfOrg' },
        { name: 'Members', code: 'members' },
        { name: 'Non-Members', code: 'non-members' },
        { name: 'Date', code: 'date' }
      ]
      const  selectOptions=[
       "COO","COO PADS","Visa Letter","Attestation","All"
      ]
      const handleMemberExcelPrint = useDownloadExcel({
        currentTableRef: excelRef.current,
        filename: "FKCCI COO GST REPORT",
        sheet: "Members",
      });
      const handleMemberExcelPrint2 = useDownloadExcel({
        currentTableRef: excelRef2.current,
        filename: "FKCCI COO Member Account Statement",
        sheet: "Members",
      });
      const handleMemberExcelPrint3 = useDownloadExcel({
        currentTableRef: excelRef3.current,
        filename: "FKCCI COO Regular Report",
        sheet: "Members",
      });
    const styles = {
        // border: "1px solid #000000",
        // width: "200px",
        paddingRight: "5px",
        marginTop: "2px",
        textAlign: "right",
      };
      const handleMemberPrint = useReactToPrint({
        content: () => invoiceRef.current,
        print: async (printIframe) => {
          // Do whatever you want here, including asynchronous work
          console.log('printIFrame',printIframe)
          // await generateAndSavePDF(printIframe);
        },
      });
      const handleMemberPrint2 = useReactToPrint({
        content: () => invoiceRef.current,
      });
    const formik = useFormik({
        initialValues: {
          nameOfOrg: "",
          pincode:"",
         startDate:"",
         endDate:"",
         reportType:"",
         cooType:"All",
         paymentType:"All",
         sortType:"",
        },
        validate: (data) => {
          let errors = {};

          return errors;
        },
    
        onSubmit: async (data) => 
        {
       console.log("datasssssssss",data)
  setLoading(true)
       const arr = [];
       Object.entries(data).map(([key, value]) => {
         arr.push({ [key]: value });
       });
       const query = await QueryGenerator(arr);
       console.log("query", query);
      getCOOReceipts(query).then(res=>{
        if(res.status===200){
          return res?.data;
        }
      }).then(res=>{
        console.log('ressss',res)
        if(res?.data?.length){
        setReportData(res)
        setPrintData(res?.data)
        setDisplayData(true);
        setLoading(false)

      }else{
        setLoading(false)
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "There are no data ",
          life: 3000,
        });
      }
      })
      .catch(err=>{
        console.log('error',err)
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Receipt couldn't be generated!",
          life: 3000,
        });
        setLoading(false)
      })
        }
      });
      const handleDownloadExcel = ()=>{
        if(payType==="gstReport"){
          setTimeout(() => {
            handleMemberExcelPrint.onDownload()
            setLoading(false)
          }, 1000);
        }
        if(payType==="memberReport"){
          count=0;
          setTimeout(() => {
            handleMemberExcelPrint2.onDownload()
            setLoading(false)
          }, 1000);
        }
        if(payType==="regularReport"){
          setExtraDetails(reportData?.total)
          setTimeout(() => {
            handleMemberExcelPrint3.onDownload()
            setLoading(false)
          }, 1000);
        }
        if(payType==="summayReport"){
          setInvoiceData(reportData?.data)
          setTimeout(() => {
            handleMemberPrint2()
            setLoading(false)
          }, 1000);
        }
      }
      const bankOptionTemplate = (option) => {
        return (
          <div className="flex flex-column align-items-center">
            <div>{option.name_of_org}</div>
          </div>
        );
      };
      const selectedBankTemplate = (option, props) => {
        if (option) {
          return (
            <div className="flex align-items-center">
              <div>{option.name_of_org}</div>
            </div>
          );
        }
    
        return <span>{props.placeholder}</span>;
      };
     

   
  useEffect(()=>{
if(search.length>3){
  getSearchMember(search).then(res=>{
    console.log('search',res)
    if(res.length===0){
    setData(...data)
    }else{
      setData(res)
    }
  }).catch(err=>{
    console.log('error',err)
  })
}
  },[search])
  formik.values.reportType=payType;
  
  const onHide = () => {
   setDisplayData(false);
  };
  return (
    <div className="justify-content-center align-items-center flex p-8">
      <Toast ref={toast}></Toast>
      <Panel header="Reports">
        <div>
          <div className="flex align-items-center">
            <div
              className="text-l align-items-center"
              style={{
                paddingRight: "5px",
                marginTop: "2px",
                textAlign: "right",
                width: "200px",
              }}
            >
              Receipt Date from
            </div>
            <Calendar
              style={{
                width: "250px",
                border: "1px solid #000000",
                borderRadius: "0px",
                marginLeft: "2px",
                textAlign: "left",
                marginTop: "2px",
              }}
              id="startDate"
              dateFormat="dd/mm/yy"
              onChange={(e) => {
                console.log("event", moment(e.value).format("DD-MM-YYYY"));
                // setAdmissionStart(moment(e.value).format("DD-MM-YYYY"));
                formik.values.startDate = moment(e.value).format("YYYY-MM-DD");
              }}
            />

            <div
              className="text-l align-items-center"
              style={{
                border: "1px solid #000000",
                width: "50px",
                padding: "10px",
                paddingTop: "10px",
                marginTop: "2px",
                textAlign: "right",
                marginLeft: "2px",
              }}
            >
              To :
            </div>
            <Calendar
              style={{
                width: "250px",
                border: "1px solid #000000",
                borderRadius: "0px",
                marginLeft: "2px",
                textAlign: "left",
                marginTop: "2px",
              }}
              id="endDate"
              dateFormat="dd/mm/yy"
              onChange={(e) => {
                // setAdmissionEnd(moment(e.value).format("DD-MM-YYYY"));
                formik.values.endDate = moment(e.value).format("YYYY-MM-DD");
              }}
            />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              justifyContent: "center",
              padding: "0px 10px",
              borderRadius: "0px",
              textAlign: "left",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <div className="flex align-items-center">
              <RadioButton
                inputId="5"
                onChange={(e) => {
                  setPayType("regularReport");
                }}
                checked={payType === "regularReport"}
              />
              <label htmlFor="5" className="ml-2">
                Regular Report
              </label>
            </div>

            {/* {userType === 'Account Team'? */}
            {/* <> */}
            <div className="flex align-items-center">
              <RadioButton
                inputId="3"
                onChange={(e) => {
                  setPayType("gstReport");
                }}
                checked={payType === "gstReport"}
              />
              <label htmlFor="3" className="ml-2">
                GST or Summary Report
              </label>
            </div>

            {/* <div className="flex align-items-center">
                    <RadioButton
                      inputId="4"
                      onChange={(e) => {
                        setPayType("summayReport")
                      }}
                      checked={
                       payType==="summayReport"
                      }
                    />
                    
                   
                   
                      <label htmlFor="4" className="ml-2">
                        Summary Report
                      </label>
                  </div> */}
            <div className="flex align-items-center">
              <RadioButton
                inputId="6"
                onChange={(e) => {
                  setPayType("memberReport");
                }}
                checked={payType === "memberReport"}
              />
              <label htmlFor="6" className="ml-2">
                Member Account Statement
              </label>
            </div>
            {/* </> */}
            {/* :null} */}
          </div>
          <div
            className="flex align-items-center"
            style={{ marginTop: "10px" }}
          >
            <div
              className="text-l align-items-center"
              style={{
                paddingRight: "5px",
                marginTop: "2px",
                textAlign: "right",
                width: "200px",
              }}
            >
              Place Of Supply :
            </div>
            <InputText
              style={{
                width: "554px",
                border: formik.errors.pincode
                  ? "1px solid red"
                  : "1px solid #000000",
                borderRadius: "0px",
                marginLeft: "2px",
                textAlign: "left",
                marginTop: "2px",
                height: "40px",
              }}
              id="pincode"
              value={formik.values.pincode}
              onChange={formik.handleChange}
            />
          </div>
          <div
            className="flex align-items-center"
            style={{ marginTop: "10px" }}
          >
            <div
              className="text-l align-items-center"
              style={{
                paddingRight: "5px",
                marginTop: "2px",
                textAlign: "right",
                width: "200px",
              }}
            >
              Member Name :
            </div>
            {/* <Dropdown
                  style={{
                    width: "554px",
                    border: formik.errors.nameOfOrg? "1px solid red":"1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                    height:"40px"
                  }}
                  id="nameOfOrg"
                  value={compony}
                  onChange={(e) => {
                    console.log("event", e.value.name);
                    setCompony(e.value);
                    formik.values.nameOfOrg = e.value.name_of_org;
                    if(e.value.member_id===null){
                    setMemberType("Non Members");
                    }else{
                    setMemberType("Members");
                    }
                  }}
                  options={data}
                  optionLabel="name_of_org"
                  filter
                  onFilter={e=>{
                    console.log('filter',e.filter)
                    setData(bankData2)
                    if(e.filter.length>3){
                      setSearch(e.filter)
                    }
                  }}
                  valueTemplate={selectedBankTemplate}
                  itemTemplate={bankOptionTemplate}
                /> */}
            <Select
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "554px",
                  border: formik.errors.nameOfOrg
                    ? "1px solid red"
                    : "1px solid #000000",
                  borderRadius: "0px",
                  marginLeft: "2px",
                  textAlign: "left",
                  marginTop: "2px",
                  height: "40px",
                }),
              }}
              id="nameOfOrg"
              value={compony}
              onChange={(selectedOption) => {
                console.log("selectedOption", selectedOption);

                setCompony(selectedOption);
                formik.values.nameOfOrg = selectedOption?.name_of_org;
                console.log(formik.values.nameOfOrg, "kjhgfdfghjkl");
                // formik.setFieldValue('nameOfOrg', selectedOption?.name_of_org || ''); // Use setFieldValue
                if (selectedOption?.member_id === null) {
                  setMemberType("Non Members");
                } else {
                  setMemberType("Members");
                }
              }}
              options={data}
              isSearchable
              onInputChange={(inputValue) => {
                // console.log(inputValue, 'kjhgfghj')
                // formik.values.nameOfOrg = inputValue?.name_of_org;

                // formik.setFieldValue('nameOfOrg',''); // Use setFieldValue
                // setCompony(null);
                setSearch(inputValue);
                if (inputValue.length > 3) {
                  // Implement your search function here
                } else {
                  // Set your data when inputValue is empty
                }
              }}
              placeholder="Search for an organization..."
              getOptionLabel={(option) => option.name_of_org}
              getOptionValue={(option) => option.member_id}
            />
          </div>
          <div
            className="flex align-items-center"
            style={{ marginTop: "10px" }}
          >
            <div
              className="text-l align-items-center"
              style={{
                paddingRight: "5px",
                marginTop: "2px",
                textAlign: "right",
                width: "200px",
              }}
            >
              Select Option:
            </div>
            <Dropdown
              style={{
                width: "554px",
                border: formik.errors.nameOfOrg
                  ? "1px solid red"
                  : "1px solid #000000",
                borderRadius: "0px",
                marginLeft: "2px",
                textAlign: "left",
                marginTop: "2px",
                height: "40px",
              }}
              id="cooType"
              value={formik.values.cooType}
              onChange={formik.handleChange}
              options={selectOptions}
            />
          </div>
          <div
            className="flex align-items-center"
            style={{ marginTop: "10px" }}
          >
            <div
              className="text-l align-items-center"
              style={{
                paddingRight: "5px",
                marginTop: "2px",
                textAlign: "right",
                width: "200px",
              }}
            >
              Payment Type:
            </div>
            <Dropdown
              style={{
                width: "554px",
                border: formik.errors.member_name_of_org
                  ? "1px solid red"
                  : "1px solid #000000",
                borderRadius: "0px",
                marginLeft: "2px",
                textAlign: "left",
                marginTop: "2px",
                height: "40px",
              }}
              id="paymentType"
              value={formik.values.paymentType}
              onChange={formik.handleChange}
              options={payType === "memberReport" ? ["Account"] : payOptions}
            />
          </div>
          <div
            className="flex align-items-center"
            style={{ marginTop: "10px" }}
          >
            <div
              className="text-l align-items-center"
              style={{
                paddingRight: "5px",
                marginTop: "2px",
                textAlign: "right",
                width: "200px",
              }}
            >
              Sort Type:
            </div>
            <Dropdown
              style={{
                width: "554px",
                border: formik.errors.member_name_of_org
                  ? "1px solid red"
                  : "1px solid #000000",
                borderRadius: "0px",
                marginLeft: "2px",
                textAlign: "left",
                marginTop: "2px",
                height: "40px",
              }}
              id="sortType"
              value={formik.values.sortType}
              onChange={formik.handleChange}
              options={sortOptions}
              optionLabel="name"
              optionValue="code"
            />
          </div>

          <div className="flex justify-content-end m-5">
            <Button
              label="OK"
              onClick={formik.handleSubmit}
              icon="pi pi-check"
              loading={isLoading}
              type="button"
            ></Button>

            <Button
              label="Cancel"
              style={{ marginLeft: "20px" }}
              icon="pi pi-check"
              type="button"
              onClick={(e) => {
                formik.handleReset();
              }}
            ></Button>
          </div>
        </div>
        <Dialog
          visible={displaydata}
          position={"centre"}
          modal
          style={{ width: "", marginTop: "" }}
          onHide={() => onHide()}
          draggable={true}
          resizable={true}
        >
          <DownloadReports
            printData={printData}
            downloadExcel={handleDownloadExcel}
            printValue={formik.values.cooType}
            startDate={formik.values.startDate}
            endDate={formik.values.endDate}
            payType={payType}
          />
        </Dialog>
      </Panel>
      <div style={{ display: "none" }}>
        <div ref={invoiceRef}>
          <Invoice data={invoiceData} type={formik.values.paymentType} />
        </div>
      </div>
      <div style={{ display: "none" }}>
        <table ref={excelRef} border="1">
          <thead>
            <tr>
              <th>GSTINUIN of Recipient</th>
              <th>Receiver Name</th>
              <th>Invoice Number</th>
              <th>Invoice Date</th>
              <th>Invoice Value</th>
              <th>Place Of Supply</th>
              <th>Reverse Charge</th>
              <th>Invoice Type</th>
              <th>ECommerce GSTIN</th>
              <th>HSN CODE</th>
              <th>Descriptions</th>
              <th>Applicable of Tax Rate</th>
              <th>IGST</th>
              <th>CGST</th>
              <th>SGST</th>
              <th>Cess Amount</th>
              <th>Round Off</th>
              <th>ADDRESS</th>
            </tr>
          </thead>
          <tbody>
            {printData?.map((val) => (
              <tr>
                <td>{val?.member_gst_no}</td>
                <td>{val.member_name_of_org}</td>
                <td>{val.receipt_no}</td>
                <td>
                  {" "}
                  {val?.created_at !== null
                    ? `${moment(val?.created_at).format("DD-MM-YYYY")}`
                    : null}
                </td>
                <td>{val.total_amount}</td>
                <td>{val.pincode}</td>
                <td></td>
                <td>{val.coo_type}</td>
                <td></td>
                <td>998214</td>
                <td></td>
                <td></td>
                <td>{val.igst}</td>
                <td>{val.cgst}</td>
                <td>{val.sgst}</td>
                <td></td>
                <td>{val.round_off_amount}</td>
                <td>{val.member_address}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ display: "none" }}>
        <table ref={excelRef2} border="1">
          <thead>
            <tr>
              <th>Sl No</th>
              <th>Receipt No.</th>
              <th>Receipt Date</th>
              <th>Description</th>
              <th>Op. Balance</th>
              <th>Amount</th>
              <th>Tatkal Amount</th>
              <th>CGST/SGST</th>
              <th>TOTAL AMT.</th>
              <th>Cl. Balance</th>
            </tr>
          </thead>
          <tbody>
            {printData?.map((val, ind) => (
              <>
                <tr>
                  <td>Member Id & Name :{val?.MEM_id_no}</td>
                  <td>{val.mem_name_of_org}</td>
                  <td></td>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {val?.details?.map((item, ind2) => (
                  <tr>
                    <td>{ind + ind2 + 1}</td>
                    <td>{item.receipt_no}</td>
                    <td>
                      {" "}
                      {item?.created_at !== null
                        ? `${moment(item?.created_at).format("DD-MM-YYYY")}`
                        : null}
                    </td>
                    <td>{`${item?.coo_type}(${item?.no_of_coo}(Extra pages: ${item?.no_of_pages}))`}</td>
                    <td>{item.opening_balance}</td>
                    <td>{item.amount}</td>
                    <td>{item.tatkal_amount}</td>
                    <td>
                      {Number(
                        (
                          (item?.igst ? Number(item?.igst) : 0) +
                          (item?.sgst ? Number(item?.sgst) : 0) +
                          (item?.cgst ? Number(item?.cgst) : 0)
                        ).toFixed(2)
                      )}
                    </td>
                    <td>{item.total_amount}</td>
                    <td>{item.closing_balance}</td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{val.total?.amount}</td>
                  <td>{val.total?.tatkal_amount}</td>
                  <td>
                    {Number(
                      (
                        (val?.total?.igst ? Number(val?.total?.igst) : 0) +
                        (val?.total?.sgst ? Number(val?.total?.sgst) : 0) +
                        (val?.total?.cgst ? Number(val?.total?.cgst) : 0)
                      ).toFixed(2)
                    )}
                  </td>
                  <td>{val.total?.total_amount}</td>
                  <td>{val.total?.closing_balance}</td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ display: "none" }}>
        <table ref={excelRef3} border="1">
          <thead>
            <tr>
              <th>Receipt No.</th>
              <th>Date</th>
              <th>Company Name</th>
              <th>Gst In</th>
              <th>Towords</th>
              <th>Amount</th>
              <th>Tatkal Amount</th>
              <th>IGST Amount</th>
              <th>CGST Amount</th>
              <th>SGST Amount</th>
              <th>Round Off Amount</th>
              <th>Total Amt</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            <tr></tr>
            <tr>
              <th>Cash</th>
            </tr>
            <tr></tr>
            {printData
              .filter((val) => val.pay_mode === "Cash")
              .map((val) => (
                <tr>
                  <td>{val?.receipt_no}</td>
                  <td>
                    {val?.created_at !== null
                      ? `${moment(val?.created_at).format("DD-MM-YYYY")}`
                      : null}
                  </td>
                  <td>
                    {val.cancel_status ? "cancelled" : val.member_name_of_org}
                  </td>
                  <td>{val.cancel_status ? "" : val.member_gst_no}</td>
                  <td>{`${val?.coo_type}(${val?.no_of_coo}(Extra pages: ${val?.no_of_pages}))`}</td>
                  <td>{val.cancel_status ? "" : val.amount}</td>
                  <td>{val.cancel_status ? "" : val.tatkal_amount}</td>
                  <td>{val.cancel_status ? "" : val.igst}</td>
                  <td>{val.cancel_status ? "" : val.cgst}</td>
                  <td>{val.cancel_status ? "" : val.sgst}</td>
                  <td>{val.cancel_status ? "" : val.round_off_amount}</td>
                  <td>{val.cancel_status ? "" : val.total_amount}</td>
                  <td>
                    {val.cancel_status
                      ? ""
                      : `${val.member_address} ${val?.state} ${val?.city}${val.pincode}`}
                  </td>
                </tr>
              ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <strong>Sub - Total</strong>
              </td>
              <td>
                <strong>{Number(totals?.cash?.amount).toFixed(2)}</strong>
              </td>
              <td>
                <strong>
                  {Number(totals?.cash?.tatkal_amount).toFixed(2)}
                </strong>
              </td>
              <td>
                <strong>{Number(totals?.cash?.igst).toFixed(2)}</strong>
              </td>
              <td>
                <strong>{Number(totals?.cash?.cgst).toFixed(2)}</strong>
              </td>
              <td>
                <strong>{Number(totals?.cash?.sgst).toFixed(2)}</strong>
              </td>
              <td>
                <strong>
                  {Number(totals?.cash?.round_off_amount).toFixed(2)}
                </strong>
              </td>
              <td>
                <strong>{Number(totals?.cash?.total_amount).toFixed(2)}</strong>
              </td>
            </tr>
            <tr></tr>
            <tr>
              <th>Account</th>
            </tr>
            <tr></tr>

            {printData
              .filter((val) => val.pay_mode === "Account")
              .map((val) => (
                <tr>
                  <td>{val?.receipt_no}</td>
                  <td>
                    {val?.created_at !== null
                      ? `${moment(val?.created_at).format("DD-MM-YYYY")}`
                      : null}
                  </td>
                  <td>
                    {val.cancel_status ? "Cancelled" : val.member_name_of_org}
                  </td>
                  <td>{val.cancel_status ? "" : val.member_gst_no}</td>
                  <td>
                    {val.cancel_status
                      ? ""
                      : `${val?.coo_type}(${val?.no_of_coo}(Extra pages: ${val?.no_of_pages}))`}
                  </td>
                  <td>{val.cancel_status ? "" : val.amount}</td>
                  <td>{val.cancel_status ? "" : val.tatkal_amount}</td>
                  <td>{val.cancel_status ? "" : val.igst}</td>
                  <td>{val.cancel_status ? "" : val.cgst}</td>
                  <td>{val.cancel_status ? "" : val.sgst}</td>
                  <td>{val.cancel_status ? "" : val.round_off_amount}</td>
                  <td>{val.cancel_status ? "" : val.total_amount}</td>
                  <td>
                    {val.cancel_status
                      ? ""
                      : `${val.member_address} ${val?.state} ${val?.city}${val.pincode}`}
                  </td>
                </tr>
              ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <strong>Sub - Total</strong>
              </td>
              <td>
                <strong>{Number(totals?.account?.amount).toFixed(2)}</strong>
              </td>
              <td>
                <strong>
                  {Number(totals?.account?.tatkal_amount).toFixed(2)}
                </strong>
              </td>
              <td>
                <strong>{Number(totals?.account?.igst).toFixed(2)}</strong>
              </td>
              <td>
                <strong>{Number(totals?.account?.cgst).toFixed(2)}</strong>
              </td>
              <td>
                <strong>{Number(totals?.account?.sgst).toFixed(2)}</strong>
              </td>
              <td>
                <strong>
                  {Number(totals?.account?.round_off_amount).toFixed(2)}
                </strong>
              </td>
              <td>
                <strong>
                  {Number(totals?.account?.total_amount).toFixed(2)}
                </strong>
              </td>
            </tr>

            <tr></tr>
            <tr></tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <strong>Total</strong>
              </td>
              <td>
                <strong>{Number(extraDetails?.total_amount).toFixed(2)}</strong>
              </td>
              <td>
                <strong>
                  {Number(extraDetails?.tatkal_amount).toFixed(2)}
                </strong>
              </td>
              <td>
                <strong>{Number(extraDetails?.totalIGST).toFixed(2)}</strong>
              </td>
              <td>
                <strong>{Number(extraDetails?.totalCGST).toFixed(2)}</strong>
              </td>
              <td>
                <strong>{Number(extraDetails?.totalSGST).toFixed(2)}</strong>
              </td>
              <td>
                <strong>
                  {Number(extraDetails?.totalRoundOffAmount).toFixed(2)}
                </strong>
              </td>
              <td>
                <strong>
                  {Number(extraDetails?.total_total_amount).toFixed(2)}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
