import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import React, { useEffect, useRef } from "react";
import Logo from "../../../../../assets/logo1.png";
import { useReactToPrint } from "react-to-print";
import { DataTable } from "primereact/datatable";
import "./DownloadReport.css";
import moment from "moment";
const dataTableStyle = {
  borderCollapse: "collapse",
  // width: "100%",
  "& th, & td": {
    borderBottom: "1px solid #ccc", // Horizontal gridlines
    padding: "8px",
    textAlign: "left",
  },
};

const dataCellStyle = {
  fontSize: "10px",
  fontWeight: "800",
};
function DownloadReports(props) {
  const { printData, downloadExcel, printValue, startDate, endDate, payType } = props;
  console.log("props", props);
  
  const reportRef = useRef(null);
  const pageStyle = `@media print {
    @page {
      size:  A4 landscape;
      margin: 0;
    }
  }`;
  // useEffect(()=>{
  //   handleMemberPrint()
  // },[])
  const handleMemberPrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => reportRef.current,
  });
 

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: "10px",
        }}
      >
        <button onClick={handleMemberPrint}>Export</button>
        <button style={{ marginLeft: "10px" }} onClick={downloadExcel}>
          Download Excel
        </button>
      </div>
      <div
        ref={reportRef}
        className="print-only"
        style={{
          width: "100%",
          padding: "20px 10px",
          marginTop: "0",
        }}
      >
        <div
          style={{
            border: "1px solid #000000",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "5px",
            padding: "10px 20px 5px 20px",
            width: "100%",
          }}
        >
          <div>
            <img src={Logo} alt="Logo" style={{ height: "70px" }} />
          </div>
          <div>
            <p
              style={{
                fontSize: "13px",
                textAlign: "center",
                lineHeight: "16px",
                fontWeight: "600",
              }}
            >
              FEDERATION OF KARNATAKA CHAMBERS OF COMMERCE & INDUSTRY
            </p>
            <p className="Text">Federation House</p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "center",
                lineHeight: "14px",
              }}
            >
              P.B.No.9996, Kempegowda Road, Bengaluru - 560009 | Ph: +91 80 2226
              2355/56
            </p>
            <p
              style={{
                fontSize: "16px",
                textAlign: "center",
                lineHeight: "18px",
                fontWeight: "300",
              }}
            >
              {printValue === "All" ? "COO / VISA / ATTE / COO" : printValue}
              PADS Details Report : {moment(startDate).format(
                "DD-MM-YYYY"
              )}{" "}
              {moment(endDate).format("DD-MM-YYYY")}
            </p>
          </div>
          <div>
            {/* <p className="textLeft2" style={{ marginTop: "60px" }}>
        CIN No: U91110KA1938NPL000244
      </p> */}
          </div>
        </div>
       {payType ==='regularReport'?regularReport(printData):payType ==='gstReport'?regularReport(printData):memberReport(printData)}
      </div>
    </>
  );
}
function regularReport (printData){
  const userType = window.localStorage.getItem("userType");

  const cashData = [];
  const accountData = [];
  const totals = {
    cash: {
      amount: 0,
      igst: 0,
      sgst: 0,
      cgst: 0,
      round_off_amount: 0,
      total_amount: 0,
      tatkal_amount: 0,
    },
    account: {
      amount: 0,
      igst: 0,
      sgst: 0,
      cgst: 0,
      round_off_amount: 0,
      total_amount: 0,
      tatkal_amount: 0,
    },
    overAll: {
      amount: 0,
      igst: 0,
      sgst: 0,
      cgst: 0,
      round_off_amount: 0,
      total_amount: 0,
      tatkal_amount: 0,
    },
  };
  printData.forEach((item) => {
    if (item.pay_mode === "Cash") {
      cashData.push(item);
    } else if (item.pay_mode === "Account") {
      accountData.push(item);
    }
  if(!item.cancel_status){  if (item.pay_mode === "Cash") {
      totals.cash.amount += parseFloat(item.amount) || 0;
      totals.cash.igst += parseFloat(item.igst) || 0;
      totals.cash.sgst += parseFloat(item.sgst) || 0;
      totals.cash.cgst += parseFloat(item.cgst) || 0;
      totals.cash.round_off_amount += parseFloat(item.round_off_amount) || 0;
      totals.cash.total_amount += parseFloat(item.total_amount) || 0;
      if (item.tatkal_amount !== null) {
        totals.cash.tatkal_amount += parseFloat(item.tatkal_amount) || 0;
      }
    }
    if (item.pay_mode === "Account") {
      totals.account.amount += parseFloat(item.amount) || 0;
      totals.account.igst += parseFloat(item.igst) || 0;
      totals.account.sgst += parseFloat(item.sgst) || 0;
      totals.account.cgst += parseFloat(item.cgst) || 0;
      totals.account.round_off_amount += parseFloat(item.round_off_amount) || 0;
      totals.account.total_amount += parseFloat(item.total_amount) || 0;
      if (item.tatkal_amount !== null) {
        totals.account.tatkal_amount += parseFloat(item.tatkal_amount) || 0;
      }
    }
    totals.overAll.amount += parseFloat(item.amount) || 0;
    totals.overAll.igst += parseFloat(item.igst) || 0;
    totals.overAll.sgst += parseFloat(item.sgst) || 0;
    totals.overAll.cgst += parseFloat(item.cgst) || 0;
    totals.overAll.round_off_amount += parseFloat(item.round_off_amount) || 0;
    totals.overAll.total_amount += parseFloat(item.total_amount) || 0;
    if (item.tatkal_amount !== null) {
      totals.overAll.tatkal_amount += parseFloat(item.tatkal_amount) || 0;
    }}
  });
  const footerGroup = (
    <ColumnGroup>
      <Row style={{ justifyContent: 'flex-end' }}>
       
        <Column footer="" style={{  width: '80px' }}/>
        <Column footer="" style={{  width: '160px' }}/>
        <Column
          footer="Total"
          style={{ fontSize: "12px", fontWeight: "800" , width: '80px' }}
        />
        <Column
          footer={totals.cash.amount.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width: '80px' }}
        />
        <Column
          footer={totals.cash.tatkal_amount.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width: '80px' }}
        />
        <Column
          footer={totals.cash.igst.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width: '80px' }}
        />
        <Column
          footer={totals.cash.cgst.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width: '80px'}}
        />
        <Column
          footer={totals.cash.sgst.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800", width: '80px'  }}
        />
        <Column
          footer={totals.cash.round_off_amount.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width: '80px' }}
        />
        <Column
          footer={totals.cash.total_amount.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800", width: '80px'  }}
        />
      </Row>
    </ColumnGroup>
  );
  const footerGroup2 = (
    <ColumnGroup>
      <Row style={{ justifyContent: 'flex-end' }}>
        <Column footer="" style={{width:'80px'}}/>
        <Column footer="" style={{width:'150px'}}/>
        <Column
          footer="Total"
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
        <Column
          footer={totals.account.amount.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
        <Column
          footer={totals.account.tatkal_amount.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
        <Column
          footer={totals.account.igst.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
        <Column
          footer={totals.account.cgst.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
        <Column
          footer={totals.account.sgst.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
        <Column
          footer={totals.account.round_off_amount.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
        <Column
          footer={totals.account.total_amount.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
      </Row>
    </ColumnGroup>
  );
  const footerGroup3 = (
    <ColumnGroup>
      <Row style={{ justifyContent: 'flex-end' }}>
        <Column footer="" style={{width:'80px'}}/>
        <Column footer="" style={{width:'150px'}}/>
        <Column
          footer="Total"
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
        <Column
          footer={totals.overAll.amount.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
        <Column
          footer={totals.overAll.tatkal_amount.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
        <Column
          footer={totals.overAll.igst.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
        <Column
          footer={totals.overAll.cgst.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
        <Column
          footer={totals.overAll.sgst.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
        <Column
          footer={totals.overAll.round_off_amount.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
        <Column
          footer={totals.overAll.total_amount.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width:'80px'}}
        />
      </Row>
    </ColumnGroup>
  );
 
const receiptNo =(data)=>{
  return(
    <><p>{data.receipt_no}</p></>
  )
}
const renderColumnWithCancelStatus = (rowData, fieldName, label) => {
  return (
    <>
      <p>{rowData.cancel_status ? '' : rowData[fieldName]}</p>
    </>
  );
};
const companyName =(data)=>{
  return(
    <><p>{data.cancel_status?'Cancelled':data.member_name_of_org}</p></>
  )
}
const gstNumberColumn = (rowData) => renderColumnWithCancelStatus(rowData, 'member_gst_no', 'GST Number');
const towardsColumn = (rowData) => renderColumnWithCancelStatus(rowData, 'coo_type', 'Towards');
const amountColumn = (rowData) => renderColumnWithCancelStatus(rowData, 'amount', 'Amount');
const tatkalAmountColumn = (rowData) => renderColumnWithCancelStatus(rowData, 'tatkal_amount', 'Tatk Amt');
const igstColumn = (rowData) => renderColumnWithCancelStatus(rowData, 'igst', 'IGST Amt');
const cgstColumn = (rowData) => renderColumnWithCancelStatus(rowData, 'cgst', 'CGST Amt');
const sgstColumn = (rowData) => renderColumnWithCancelStatus(rowData, 'sgst', 'SGST Amt');
const roundOffColumn = (rowData) => renderColumnWithCancelStatus(rowData, 'round_off_amount', 'Round Off');
const totalAmountColumn = (rowData) => renderColumnWithCancelStatus(rowData, 'total_amount', 'Total Amt');

  
  return(
    <>
     {cashData.length ? (
          <>
            <p>Payment : Cash</p>
            <DataTable
              value={cashData}
              style={dataTableStyle}
              size={"small"}
              // footerColumnGroup={footerGroup}
              className="table-borderless" 
            >
              <Column
                style={{ ...dataCellStyle }}
                field={receiptNo}
                header={<div style={{ fontSize: "12px" }}>Rcpt No</div>}
                // footer={<div>Total:</div>} // Move the footer here
              />
              <Column
                style={{ ...dataCellStyle }}
                field={companyName}
                header={<div style={{ fontSize: "12px" }}>Company Name</div>}
              />
              {userType === "Account Team" && (
                <Column
                  style={{ ...dataCellStyle }}
                  field={gstNumberColumn}
                  header={<div style={{ fontSize: "12px" }}>GST Number</div>}
                />
              )}
              <Column
                style={{ ...dataCellStyle }}
                field={towardsColumn}
                header={<div style={{ fontSize: "12px" }}>Towards</div>}
              />
              <Column
                style={{ ...dataCellStyle }}
                field={amountColumn}
                header={<div style={{ fontSize: "12px" }}>Amount</div>}
              />
              <Column
                style={{ ...dataCellStyle }}
                field={tatkalAmountColumn}
                header={<div style={{ fontSize: "12px" }}>Tatk Amt</div>}
              />
              <Column
                style={{ ...dataCellStyle }}
                field={igstColumn}
                header={<div style={{ fontSize: "12px" }}>IGST Amt</div>}
              />
              <Column
                style={{ ...dataCellStyle }}
                field={cgstColumn}
                header={<div style={{ fontSize: "12px" }}>CGST Amt</div>}
              />
              <Column
                style={{ ...dataCellStyle }}
                field={sgstColumn}
                header={<div style={{ fontSize: "12px" }}>SGST Amt</div>}
              />
              <Column
                style={{ ...dataCellStyle }}
                field={roundOffColumn}
                header={<div style={{ fontSize: "12px" }}>Round Off</div>}
              />
              <Column
                style={{ ...dataCellStyle }}
                field={totalAmountColumn}
                header={<div style={{ fontSize: "12px" }}>Total Amt</div>}
              />
            </DataTable>
            {/* Footer */}
            <DataTable
              value={cashData}
              style={dataTableStyle}
              footerColumnGroup={footerGroup}
              ></DataTable>
          </>
        ) : null}
        {accountData.length ? (
          <>
            <p>Payment : Account</p>
            <DataTable
              value={accountData}
              style={dataTableStyle}
              // footerColumnGroup={footerGroup2}
            >
              <Column
                style={{ ...dataCellStyle }}
                field={receiptNo}
                header={<div style={{ fontSize: "12px" }}>Rcpt No</div>}
              />
              <Column
                style={{ ...dataCellStyle }}
                field={companyName}
                header={<div style={{ fontSize: "12px" }}>Company Name</div>}
              />
               {userType === "Account Team" && (
                <Column
                  style={{ ...dataCellStyle }}
                  field={gstNumberColumn}
                  header={<div style={{ fontSize: "12px" }}>GST Number</div>}
                />
              )}
            <Column
                style={{ ...dataCellStyle }}
                field={amountColumn}
                header={<div style={{ fontSize: "12px" }}>Amount</div>}
              />
              <Column
                style={{ ...dataCellStyle }}
                field={tatkalAmountColumn}
                header={<div style={{ fontSize: "12px" }}>Tatk Amt</div>}
              />
              <Column
                style={{ ...dataCellStyle }}
                field={igstColumn}
                header={<div style={{ fontSize: "12px" }}>IGST Amt</div>}
              />
              <Column
                style={{ ...dataCellStyle }}
                field={cgstColumn}
                header={<div style={{ fontSize: "12px" }}>CGST Amt</div>}
              />
              <Column
                style={{ ...dataCellStyle }}
                field={sgstColumn}
                header={<div style={{ fontSize: "12px" }}>SGST Amt</div>}
              />
              <Column
                style={{ ...dataCellStyle }}
                field={roundOffColumn}
                header={<div style={{ fontSize: "12px" }}>Round Off</div>}
              />
              <Column
                style={{ ...dataCellStyle }}
                field={totalAmountColumn}
                header={<div style={{ fontSize: "12px" }}>Total Amt</div>}
              />
            </DataTable>
            <DataTable
              value={cashData}
              style={dataTableStyle}
              footerColumnGroup={footerGroup2}
              ></DataTable>

            <p>OverAll Total</p>

               <DataTable
              value={cashData}
              style={dataTableStyle}
              footerColumnGroup={footerGroup3}
              ></DataTable>
          </>
        ) : null}
    </>
  )
}
function memberReport(printData){
  console.log(printData, 'checkingggg')
  const cooType=(data)=>{
return(
  <>{
    data.coo_type === 'COO'?
  <p>Cert Of Origin Details{'('}{data.no_of_coo}{'(Extra Pages:'}{data.no_of_pages}{')'}{')'}</p>
: 
<p>{data.coo_type}{data.no_of_coo}{data.no_of_pages}</p>
  }</>
)
  }
  const dateFormat =(data)=>{
    return(
      <p>{moment(data?.created_at).format("DD-MM-YYYY")}</p>
    )
  }
  const gst = (data) => {
    return (
      <p>{(Number(data.sgst) + Number(data.cgst))}</p>
    );
  };
  const footerGroup = (data)=>{
    return(
      <ColumnGroup>
      <Row style={{ justifyContent: 'flex-end' }}>
       
        <Column footer="" style={{  width: '80px' }}/>
        <Column footer="" style={{  width: '80px' }}/>
        <Column footer="" style={{  width: '80px' }}/>
        <Column footer="" style={{  width: '220px' }}/>
        <Column
          footer={data?.opening_balance?.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800", width: '80px'  }}
        />
       <Column
          footer={data?.amount?.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width: '80px' }}
        />
         <Column
          footer={data?.tatkal_amount?.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800" , width: '80px' }}
        />
        
        <Column
          footer={Number(data?.cgst?.toFixed(2))+Number(data?.sgst?.toFixed(2))}
          style={{ fontSize: "12px", fontWeight: "800" , width: '80px'}}
        />
        
      
        <Column
          footer={data?.total_amount?.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800", width: '80px'  }}
        />

        <Column
          footer={data?.closing_balance?.toFixed(2)}
          style={{ fontSize: "12px", fontWeight: "800", width: '80px'  }}
        />
      </Row>
    </ColumnGroup>
  );
  }
    
 
  return(
    <>
    <DataTable value={[]} style={{padding:'0'}}className="no-results-found">
      <Column style={{fontSize:'10px'}} header="SL NO" />
      <Column style={{fontSize:'10px'}} header="Receipt No" />
      <Column style={{fontSize:'10px'}} header="Receipt Date" />
      <Column style={{fontSize:'10px'}} header="Description" />
      <Column style={{fontSize:'10px'}} header="OP Balance" />
      <Column style={{fontSize:'10px'}} header="Amount" />
      <Column style={{fontSize:'10px'}} header="Tat Amt" />
      <Column style={{fontSize:'10px'}} header="CGST/SGST" />
      <Column style={{fontSize:'10px'}} header="Total Amt" />
      <Column style={{fontSize:'10px'}} header="CL Balance" />



    </DataTable>
    {
      printData.map((data)=>{ return(
        <>
        <div>
          <p style={{fontWeight:'bold', fontSize:'13px'}}>Member Id & Name : {data.MEM_id_no}{' '}{data.mem_name_of_org}</p>
        </div>
        <DataTable
        value={data.details}
        style={dataTableStyle}
        size={"small"}
        footerColumnGroup={footerGroup(data.total)}
        className="table-borderless" 
  headerStyle={{ display: 'none' }}
      >
        <Column
          style={{...dataCellStyle,width:'5px'}}
          field="slNo"
        />
        <Column
          style={{...dataCellStyle}}
          field="receipt_no"
        />
        <Column
          style={{...dataCellStyle}}
          field={dateFormat}
        />
        <Column
          style={{...dataCellStyle}}
          field={cooType}
        />
          <Column
          style={{...dataCellStyle}}
          field="opening_balance"
        />
         <Column
          style={{...dataCellStyle}}
          field="amount"
        />
        <Column 
        style={{...dataCellStyle}}
        field="tatkal_amount"
      />
     
     <Column
    style={{...dataCellStyle}}
    field={gst}
  /> 
   <Column
  style={{...dataCellStyle}}
  field="total_amount"
/>
         <Column
          style={{...dataCellStyle}}
          field="closing_balance"
        />
        </DataTable>
        </>
       ) })
    }
    </>
  )

}
export default DownloadReports;
