import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import Invoice from "./components/Invoice";
import { useReactToPrint } from "react-to-print";
import { getSearchMember, registerCOO } from "../../../../../apis/api";
import { Toast } from "primereact/toast";
import Select from "react-select";

export const Coo = () => {
  const [compony, setCompony] = useState("");

  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);

  const [memberType, setMemberType] = useState("Members");
  const [payType, setPayType] = useState("");
  const [search, setSearch] = useState("");
  const [update, setUpdate] = useState(false);

  const [data, setData] = useState([{}]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [accountBalance, setAccountBalance] = useState("0.00");

  const [gstState, setGstState] = useState("");

  const invoiceRef = useRef(null);
  const toast = useRef(null);
  const selectRef = useRef(null);

  useEffect(() => {
    selectRef.current.focus();
  }, []);
  const styles = {
    // border: "1px solid #000000",
    // width: "200px",
    paddingRight: "5px",
    marginTop: "2px",
    textAlign: "right",
  };
  const handleMemberPrint = useReactToPrint({
    content: () => invoiceRef.current,
  });
  const formik = useFormik({
    initialValues: {
      member_id: "",
      member_name_of_org: "",
      member_address: "",
      member_gst_no: "",
      is_tatkal_service: false,
      pay_mode: "Cash",
      coo_member_type: "",
      coo_type: "",
      no_of_coo: "1",
      is_special_case: false,
      no_of_pages: "0",
      amount: "",
      sgst: "",
      cgst: "",
      igst: "",
      round_off_amount: "",
      total_amount: "",
      tatkal_amount: "0.00",
      no_of_days: "",
      phone_number: "",
      account_balance: "0.00",
      state: "",
      city: "",
      pincode: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.member_name_of_org) {
        errors.member_name_of_org = "This Field is Required.";
      }
      // if (!data.member_address) {
      //   errors.member_address = "This Field is Required.";
      // }
      // if (!data.member_gst_no) {
      //   errors.member_gst_no = "This Field is Required.";
      // }
      if (!data.coo_member_type) {
        errors.coo_member_type = "This Field is Required.";
      }
      if (!data.coo_type) {
        errors.coo_type = "This Field is Required.";
      }
      if (!data.pay_mode) {
        errors.pay_mode = "This Field is Required.";
      }
      if (!data.city) {
        errors.city = "This Field is Required.";
      }
      if (!data.state) {
        errors.state = "This Field is Required.";
      }
      if (!data.pincode) {
        errors.pincode = "This Field is Required.";
      }
      if (data.pincode.length !== 6) {
        errors.pincode = "Enter a valid Pincode";
      }
      if (data.no_of_coo < 1) {
        errors.no_of_coo = "no of coo must not be less that 1";
      }
      // if (data.phone_number<10 && memberType === 'Non Members') {
      //   errors.phone_number = "Phone Number is mandatory";
      // }
      console.log(errors, "errors");
      return errors;
    },

    onSubmit: async (data) => {
      setLoading(true);
      const registerData = {};
      console.log(data, "data");
      console.log(
        Object.keys(data)
          .filter((item) => data[item].length !== 0)
          .map((key) => (registerData[key] = data[key]))
      );
      console.log("data", registerData);
      const { member_id, account_balance, ...newdata } = registerData;
      let nData = {};
      if (data.coo_member_type === "Non Members") {
        nData = newdata;
      } else {
        nData = { ...newdata, member_id: data.member_id };
      }
      console.log("data", nData);
      registerCOO(nData)
        .then((res) => {
          console.log("res", res);
          if (res?.status === 200) {
            return res?.data;
          }
        })
        .then((res) => {
          console.log("res2", res);
          if (res) {
            setInvoiceData(res);
            setTimeout(() => {
              handleMemberPrint();
              setLoading(false);
            }, 1000);
          } else {
            setLoading(false);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Receipt couldn't be generated!",
              life: 3000,
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        })
        .finally((res) => {
          setSelectedOption(null);
          setCompony("");
          setChecked(false);
          setChecked2(false);
          setPayType("");
          setMemberType("Members");
          formik.values.member_name_of_org = "";
          formik.values.member_gst_no = "";
          formik.values.member_address = "";
          formik.values.member_id = "";
          formik.values.account_balance = "0.00";
          formik.values.amount = "";
          formik.values.sgst = "";
          formik.values.cgst = "";
          formik.values.igst = "";
          formik.values.round_off_amount = "";
          formik.values.total_amount = "";
          formik.values.no_of_coo = "1";
          formik.values.pay_mode = "Cash";
          formik.values.no_of_pages = "0";
          formik.values.tatkal_amount = "0.00";
          formik.values.no_of_days = "";
          formik.values.state = "";
          formik.values.city = "";
          formik.values.pincode = "";
          formik.values.phone_number = "";
        });
    },
  });
  const bankOptionTemplate = (option) => {
    return (
      <div className="flex flex-column align-items-center">
        <div>{option.name_of_org}</div>
      </div>
    );
  };
  const selectedBankTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name_of_org}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const calculateAmount = () => {
    const amt =
      Number(formik.values.amount) + Number(formik.values.tatkal_amount);

    const gstNum = formik.values.member_gst_no.substring(0, 2);
    console.log(gstNum, "finding");
    if (gstNum === "29") {
      formik.values.cgst = Number(amt * 0.09).toFixed(2);
      formik.values.sgst = Number(amt * 0.09).toFixed(2);
      formik.values.igst = Number(0).toFixed(2);
    } else if(gstNum === ""){
        if (
          formik.values.state === "Karnataka" ||
          formik.values.state === "karnataka"
        ) {
            formik.values.cgst = Number(amt * 0.09).toFixed(2);
            formik.values.sgst = Number(amt * 0.09).toFixed(2);
            formik.values.igst = Number(0).toFixed(2);
        } else {
          formik.values.cgst = Number(0).toFixed(2);
          formik.values.sgst = Number(0).toFixed(2);
          formik.values.igst = Number(amt * 0.18).toFixed(2);
        }
    }else {
      formik.values.cgst = Number(0).toFixed(2);
      formik.values.sgst = Number(0).toFixed(2);
      formik.values.igst = Number(amt * 0.18).toFixed(2);
    }

    formik.values.total_amount = Math.round(
      Number(formik.values.amount) +
        Number(formik.values.tatkal_amount) +
        Number(formik.values.cgst) +
        Number(formik.values.sgst) +
        Number(formik.values.igst)
    ).toFixed(2);
    formik.values.round_off_amount = Math.abs(
      Number(formik.values.total_amount) -
        Number(formik.values.amount) -
        Number(formik.values.tatkal_amount) -
        Number(formik.values.cgst) -
        Number(formik.values.sgst) -
        Number(formik.values.igst)
    ).toFixed(2);
    const fullAmount = (
      Number(formik.values.amount) +
      Number(formik.values.tatkal_amount) +
      Number(formik.values.cgst) +
      Number(formik.values.sgst) +
      Number(formik.values.igst)
    ).toFixed(2);
    
    if (fullAmount > formik.values.total_amount) {
      formik.values.round_off_amount = -formik.values.round_off_amount;
    }
    console.log(fullAmount, formik.values.total_amount, 'checkinghghg')
    // formik.values.round_off_amount=Number(1-formik.values.round_off_amount).toFixed(2)
  };
  if (!checked2) {
    if (payType === "COO") {
      console.log("extra page", formik.values.no_of_pages);
      if (memberType === "Members") {
        formik.values.amount = Number(70 * formik.values.no_of_coo).toFixed(2);
        formik.values.amount = Number(
          Number(formik.values.amount) + Number(formik.values.no_of_pages * 7)
        ).toFixed(2);
        if (checked) {
          formik.values.tatkal_amount = Number(
            88 * formik.values.no_of_coo
          ).toFixed(2);
        } else {
          formik.values.tatkal_amount = Number(0).toFixed(2);
        }
        calculateAmount();
      } else {
        formik.values.amount = Number(132 * formik.values.no_of_coo).toFixed(2);
        formik.values.amount = Number(
          Number(formik.values.amount) + Number(formik.values.no_of_pages * 15)
        ).toFixed(2);
        if (checked) {
          formik.values.tatkal_amount = Number(
            88 * formik.values.no_of_coo
          ).toFixed(2);
        } else {
          formik.values.tatkal_amount = Number(0).toFixed(2);
        }
        calculateAmount();
      }
    } else if (payType === "Attestation") {
      if (memberType === "Members") {
        formik.values.amount = Number(200 * formik.values.no_of_coo).toFixed(2);
        formik.values.amount = Number(
          Number(formik.values.amount) + Number(formik.values.no_of_pages * 20)
        ).toFixed(2);
        if (checked) {
          formik.values.tatkal_amount = Number(
            88 * formik.values.no_of_coo
          ).toFixed(2);
        } else {
          formik.values.tatkal_amount = Number(0).toFixed(2);
        }
        calculateAmount();
      } else {
        formik.values.amount = Number(250 * formik.values.no_of_coo).toFixed(2);
        formik.values.amount = Number(
          Number(formik.values.amount) + Number(formik.values.no_of_pages * 25)
        ).toFixed(2);
        if (checked) {
          formik.values.tatkal_amount = Number(
            88 * formik.values.no_of_coo
          ).toFixed(2);
        } else {
          formik.values.tatkal_amount = Number(0).toFixed(2);
        }
        calculateAmount();
      }
    } else if (payType === "Visa Letter") {
      if (memberType === "Members") {
        if (formik.values.no_of_days === "3 months") {
          formik.values.amount = Number(200 * formik.values.no_of_coo).toFixed(
            2
          );
          if (checked) {
            formik.values.tatkal_amount = Number(
              88 * formik.values.no_of_coo
            ).toFixed(2);
          } else {
            formik.values.tatkal_amount = Number(0).toFixed(2);
          }
        } else if (formik.values.no_of_days === "6 months") {
          formik.values.amount = Number(400 * formik.values.no_of_coo).toFixed(
            2
          );
          if (checked) {
            formik.values.tatkal_amount = Number(
              176 * formik.values.no_of_coo
            ).toFixed(2);
          } else {
            formik.values.tatkal_amount = Number(0).toFixed(2);
          }
        } else if (formik.values.no_of_days === "9 months") {
          formik.values.amount = Number(600 * formik.values.no_of_coo).toFixed(
            2
          );
          if (checked) {
            formik.values.tatkal_amount = Number(
              264 * formik.values.no_of_coo
            ).toFixed(2);
          } else {
            formik.values.tatkal_amount = Number(0).toFixed(2);
          }
        } else if (formik.values.no_of_days === "1 year") {
          formik.values.amount = Number(
            847.46 * formik.values.no_of_coo
          ).toFixed(2);
          if (checked) {
            formik.values.tatkal_amount = Number(
              424 * formik.values.no_of_coo
            ).toFixed(2);
          } else {
            formik.values.tatkal_amount = Number(0).toFixed(2);
          }
        }
        calculateAmount();

        // if(checked){
        //     if(formik.values.no_of_days==='3 months'){
        //         formik.values.amount=Number(288*formik.values.no_of_coo).toFixed(2);
        //     }
        //     else if(formik.values.no_of_days==='6 months'){
        //         formik.values.amount=Number(576*formik.values.no_of_coo).toFixed(2);
        //     }
        //     else if(formik.values.no_of_days==='9 months'){
        //         formik.values.amount=Number(864*formik.values.no_of_coo).toFixed(2);
        //     }
        //     else if(formik.values.no_of_days==='1 year'){
        //         formik.values.amount=Number(1271*formik.values.no_of_coo).toFixed(2);
        //     }
        //     calculateAmount()
        // }
        // else{
        //     if(formik.values.no_of_days==='3 months'){
        //         formik.values.amount=Number(200*formik.values.no_of_coo).toFixed(2);
        //     }
        //     else if(formik.values.no_of_days==='6 months'){
        //         formik.values.amount=Number(400*formik.values.no_of_coo).toFixed(2);
        //     }
        //     else if(formik.values.no_of_days==='9 months'){
        //         formik.values.amount=Number(600*formik.values.no_of_coo).toFixed(2);
        //     }
        //     else if(formik.values.no_of_days==='1 year'){
        //         formik.values.amount=Number(847*formik.values.no_of_coo).toFixed(2);
        //     }
        //     calculateAmount()
        // }
      } else {
        if (formik.values.no_of_days === "3 months") {
          formik.values.amount = Number(300 * formik.values.no_of_coo).toFixed(
            2
          );
          if (checked) {
            formik.values.tatkal_amount = Number(88).toFixed(2);
          } else {
            formik.values.tatkal_amount = Number(0).toFixed(2);
          }
        } else if (formik.values.no_of_days === "6 months") {
          formik.values.amount = Number(600 * formik.values.no_of_coo).toFixed(
            2
          );
          if (checked) {
            formik.values.tatkal_amount = Number(176).toFixed(2);
          } else {
            formik.values.tatkal_amount = Number(0).toFixed(2);
          }
        } else if (formik.values.no_of_days === "9 months") {
          formik.values.amount = Number(900 * formik.values.no_of_coo).toFixed(
            2
          );
          if (checked) {
            formik.values.tatkal_amount = Number(264).toFixed(2);
          } else {
            formik.values.tatkal_amount = Number(0).toFixed(2);
          }
        } else if (formik.values.no_of_days === "1 year") {
          formik.values.amount = Number(1271 * formik.values.no_of_coo).toFixed(
            2
          );
          if (checked) {
            formik.values.tatkal_amount = Number(423.5).toFixed(2);
          } else {
            formik.values.tatkal_amount = Number(0).toFixed(2);
          }
        }
        calculateAmount();
      }
    } else if (payType === "COO PADS") {
      formik.values.tatkal_amount = Number(0).toFixed(2);
      formik.values.amount = Number(300 * formik.values.no_of_coo).toFixed(2);
      calculateAmount();
    }
  } else {
    formik.values.amount =
      Number((formik.values.total_amount * 100) / 118).toFixed(2) -
      Number(formik.values.tatkal_amount);
    const gst = Number((formik.values.total_amount * 18) / 118).toFixed(2);

    const gstNum = formik.values.member_gst_no.substring(0, 2);
    if (gstNum === "29") {
      formik.values.cgst = Number(gst / 2).toFixed(2);
      formik.values.sgst = Number(gst / 2).toFixed(2);
      formik.values.igst = Number(0).toFixed(2);
    } else {
      formik.values.cgst = Number(0).toFixed(2);
      formik.values.sgst = Number(0).toFixed(2);
      formik.values.igst = Number(gst).toFixed(2);
    }

    if (checked) {
      formik.values.tatkal_amount = Number(
        88 * formik.values.no_of_coo
      ).toFixed(2);
    } else {
      formik.values.tatkal_amount = Number(0).toFixed(2);
    }
    formik.values.round_off_amount = Math.abs(
      Number(formik.values.total_amount) -
        Number(formik.values.tatkal_amount) -
        Number(formik.values.amount) -
        Number(formik.values.cgst) -
        Number(formik.values.sgst) -
        Number(formik.values.igst)
    ).toFixed(2);
  }
  formik.values.is_special_case = checked2;
  formik.values.is_tatkal_service = checked;
  formik.values.coo_member_type = memberType;
  formik.values.coo_type = payType;
  const searchFunction = async (search) => {
    await getSearchMember(search)
      .then((res) => {
        console.log(res, "resres");
        if (res && res.length > 0) {
          const filteredOptions = res.filter((data) =>
            data.name_of_org.toLowerCase().includes(search)
          );
          setData(filteredOptions);
        } else {
          console.log("res", res);
          setData([
            ...res,
            { name_of_org: search, member_id: null, credit: null },
          ]);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const payOptions =
    formik.values.account_balance < formik.values.total_amount
      ? ["Cash"]
      : ["Cash", "Account"];
  const durOptions = ["3 months", "6 months", "9 months", "1 year"];

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    formik.values.member_name_of_org = selectedOption?.name_of_org || "";
    formik.values.member_gst_no = selectedOption?.GSTIN || "";
    formik.values.member_address = selectedOption?.address || "";
    formik.values.member_id = selectedOption?.member_id || "";
    formik.values.state = selectedOption?.state || "";
    formik.values.city = selectedOption?.city || "";
    formik.values.pincode = selectedOption?.pincode || "";
    formik.values.phone_number = selectedOption?.mobile || "";

    formik.values.account_balance =
      selectedOption?.credit !== null ? selectedOption.credit : "0.00";
    if (selectedOption.member_id === null) {
      setMemberType("Non Members");
    } else {
      setMemberType("Members");
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: formik.errors.member_name_of_org
        ? "1px solid red"
        : "1px solid #000000",
      borderRadius: "0px",
      marginLeft: "2px",
      textAlign: "left",
      marginTop: "2px",
      height: "40px",
      width: "469px",
    }),
  };
  return (
    <div className="justify-content-center align-items-center flex p-8">
      <Toast ref={toast}></Toast>
      <Panel header="Certificate of Origin">
        <div>
          <div className="flex align-items-start">
            <div
              className="text-l align-items-center"
              style={{
                paddingRight: "5px",
                marginTop: "2px",
                textAlign: "right",
                width: "200px",
              }}
            >
              Member Name
            </div>
            {/* {memberType === "Members" ? ( */}
            {/* <Dropdown
                style={{
                  width: "460px",
                  border: formik.errors.member_name_of_org
                    ? "1px solid red"
                    : "1px solid #000000",
                  borderRadius: "0px",
                  marginLeft: "2px",
                  textAlign: "left",
                  marginTop: "2px",
                  height: "40px",
                }}
                id="member_name_of_org"
                value={compony}
                onChange={(e) => {
                  console.log(e.value , 'e.value')
                  setCompony(e.value);

                  formik.values.member_name_of_org = e.value.name_of_org;
                  formik.values.member_gst_no = e.value.GSTIN ?? "";
                  formik.values.member_address = e.value.address ?? "";
                  formik.values.member_id = e.value.member_id ?? "";
                  formik.values.state = e.value.state ?? "";
                  formik.values.city = e.value.city ?? "";
                  formik.values.pincode = e.value.pincode ?? "";

                  formik.values.account_balance =
                    e.value.credit !== null ? e.value.credit : "0.00";
                    setAccountBalance(e.value.credit !== null ? e.value.credit : "0.00")
                    console.log(formik.values.account_balance , 'fixinggg')
                  if (e.value.member_id === null) {
                    setMemberType("Non Members");
                  } else {
                    setMemberType("Members");
                  }
                }}
                options={data}
                optionLabel="name_of_org"
                filter
                onFilter={(e) => {
                  if (e.filter) {
                    setSearch(e.filter);
                    if (search.length > 3) {
                      searchFunction(e.filter);
                    } else {
                      setData([{name_of_org:e.filter}]);
                    }
                  }
                }}
                valueTemplate={selectedBankTemplate}
                itemTemplate={bankOptionTemplate}
              /> */}
            <Select
              ref={selectRef}
              styles={customStyles}
              value={selectedOption}
              onChange={handleSelectChange}
              options={data}
              isSearchable
              onInputChange={(inputValue) => {
                // setSelectedOption(null)
                // setData([{}]);
                if (inputValue) {
                  setSearch(inputValue);
                  if (search.length > 1) {
                    searchFunction(inputValue);
                  } else {
                    setData([{ name_of_org: inputValue }]);
                  }
                }
              }}
              placeholder="Search for an organization..."
              getOptionLabel={(option) => option.name_of_org}
              getOptionValue={(option) => option.member_id}
            />
          </div>
          <div className="flex align-items-center mt-2">
            <div
              className="card flex justify-content-end align-items-center gap-1"
              style={{
                paddingRight: "5px",
                marginTop: "2px",
                textAlign: "right",
                width: "200px",
              }}
            >
              {/* <Checkbox
                onChange={(e) => setChecked(e.checked)}
                checked={checked}
              ></Checkbox> */}
              <div className="text-l align-items-center" style={styles}>
                Address
              </div>
            </div>

            <InputText
              style={{
                width: "460px",
                border: formik.errors.member_address
                  ? "1px solid red"
                  : "1px solid #000000",
                borderRadius: "0px",
                marginLeft: "2px",
                textAlign: "left",
                marginTop: "2px",
                height: "40px",
              }}
              id="member_address"
              value={formik.values.member_address}
              onChange={formik.handleChange}
            ></InputText>
          </div>

          <div className="flex align-items-center gap-4  mt-2 justify-content-end">
            <div className="flex align-items-center mt-2">
              <div
                className="text-l align-items-center"
                style={{
                  paddingRight: "5px",
                  marginTop: "2px",
                  textAlign: "right",
                  // width: "80px",
                }}
              >
                City
              </div>

              <InputText
                style={{
                  width: "100px",
                  border: formik.errors.city
                    ? "1px solid red"
                    : "1px solid #000000",
                  borderRadius: "0px",
                  marginLeft: "2px",
                  textAlign: "left",
                  marginTop: "2px",
                  height: "40px",
                }}
                id="city"
                value={formik.values.city}
                onChange={formik.handleChange}
              ></InputText>
            </div>
            <div className="flex align-items-center mt-2">
              <div
                className="text-l align-items-center"
                style={{
                  // paddingRight: "5px",
                  marginTop: "2px",
                  textAlign: "right",
                  // width: "80px",
                  // marginRight:'8px'
                }}
              >
                State
              </div>
              <InputText
                style={{
                  width: "100px",
                  border: formik.errors.state
                    ? "1px solid red"
                    : "1px solid #000000",
                  borderRadius: "0px",
                  marginLeft: "2px",
                  marginRight: "5px",
                  textAlign: "left",
                  marginTop: "2px",
                  height: "40px",
                }}
                id="state"
                value={formik.values.state}
                onChange={formik.handleChange}
              ></InputText>
            </div>
            <div className="flex align-items-center mt-2">
              <div
                className="text-l align-items-center"
                style={{
                  paddingRight: "5px",
                  marginTop: "2px",
                  textAlign: "right",
                  // width: "100px",
                }}
              >
                Pin Code
              </div>

              <InputText
                style={{
                  width: "100px",
                  border: formik.errors.pincode
                    ? "1px solid red"
                    : "1px solid #000000",
                  borderRadius: "0px",
                  marginLeft: "2px",
                  textAlign: "left",
                  marginTop: "2px",
                  height: "40px",
                }}
                id="pincode"
                value={formik.values.pincode}
                onChange={formik.handleChange}
              ></InputText>
            </div>
          </div>
          <div className="flex align-items-center mt-2">
            <div
              className="text-l align-items-center"
              style={{
                paddingRight: "5px",
                marginTop: "2px",
                textAlign: "right",
                width: "200px",
              }}
            >
              Gst No .
            </div>

            <InputText
              style={{
                width: "460px",
                border: formik.errors.member_gst_no
                  ? "1px solid red"
                  : "1px solid #000000",
                borderRadius: "0px",
                marginLeft: "2px",
                textAlign: "left",
                marginTop: "2px",
                height: "40px",
              }}
              id="member_gst_no"
              value={formik.values.member_gst_no}
              onChange={(e) => {
                const uppercaseValue = e.target.value.toUpperCase();
                formik.setFieldValue("member_gst_no", uppercaseValue);
              }}
            ></InputText>
          </div>

          {/* {memberType !== "Members" ? ( */}
          <div className="flex align-items-center mt-2">
            <div
              className="text-l align-items-center"
              style={{
                paddingRight: "5px",
                marginTop: "2px",
                textAlign: "right",
                width: "200px",
              }}
            >
              Phone Number
            </div>

            <InputText
              style={{
                width: "460px",
                border: formik.errors.phone_number
                  ? "1px solid red"
                  : "1px solid #000000",
                borderRadius: "0px",
                marginLeft: "2px",
                textAlign: "left",
                marginTop: "2px",
                height: "40px",
              }}
              id="phone_number"
              value={formik.values.phone_number}
              onChange={formik.handleChange}
            ></InputText>
          </div>
          {/* ) : (
            ""
          )} */}
          <div className="flex mt-2 gap-6 align-items-center ">
            <div
              style={{
                width: "300px",
                display: "flex",
                gap: "10px",
                justifyContent: "space-between",
                padding: "0px 10px",
                border: formik.errors.coo_member_type
                  ? "1px solid red"
                  : "1px solid #000000",
                borderRadius: "0px",
                textAlign: "left",
                marginTop: "2px",
                height: "45px",
              }}
            >
              <div className="flex align-items-center">
                <RadioButton
                  inputId="1"
                  value={"New Admission"}
                  onChange={(e) => {
                    setMemberType("Members");
                    setCompony("");
                    formik.values.member_name_of_org = "";
                    formik.values.member_address = "";
                    formik.values.member_gst_no = "";
                    formik.values.state = "";
                    formik.values.city = "";
                    formik.values.pincode = "";
                    formik.values.phone_number = "";
                  }}
                  checked={memberType === "Members"}
                />
                <label htmlFor="1" className="ml-2">
                  Members
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="2"
                  value={"Subscription Renewal"}
                  onChange={(e) => {
                    setMemberType("Non Members");
                    setCompony("");
                    formik.values.member_name_of_org = "";
                    formik.values.member_address = "";
                    formik.values.member_gst_no = "";
                    formik.values.state = "";
                    formik.values.city = "";
                    formik.values.pincode = "";
                    formik.values.phone_number = "";
                  }}
                  checked={memberType === "Non Members"}
                />

                <label htmlFor="2" className="ml-2">
                  Non Members
                </label>
              </div>
            </div>
            <div className="flex align-items-center">
              <div className="text-l align-items-center" style={styles}>
                Pay Mode
              </div>

              <Dropdown
                showClear
                options={payOptions}
                id="pay_mode"
                value={formik.values.pay_mode}
                onChange={formik.handleChange}
                placeholder="Not selected"
                style={{
                  width: "223px",
                  border: formik.errors.pay_mode
                    ? "1px solid red"
                    : "1px solid #000000",
                  borderRadius: "0px",
                  marginLeft: "2px",
                  textAlign: "left",
                  marginTop: "2px",
                  height: "45px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              />
            </div>
          </div>
          <div className="flex  gap-6 align-items-center ">
            <div
              style={{
                width: "300px",
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                justifyContent: "space-between",
                padding: "0px 10px",
                border: formik.errors.coo_type
                  ? "1px solid red"
                  : "1px solid #000000",
                borderRadius: "0px",
                textAlign: "left",
                padding: "10px",
                borderTop:
                  payType === "Visa Letter"
                    ? "1px solid #000000"
                    : "0px solid #000000",
              }}
            >
              <div className="flex align-items-center">
                <RadioButton
                  inputId="3"
                  value={"New Admission"}
                  onChange={(e) => {
                    setPayType("COO");
                  }}
                  checked={payType === "COO"}
                />
                <label htmlFor="3" className="ml-2">
                  COO
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="4"
                  value={"Subscription Renewal"}
                  onChange={(e) => {
                    setPayType("Visa Letter");
                  }}
                  checked={payType === "Visa Letter"}
                />

                <label htmlFor="4" className="ml-2">
                  Visa Letter
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="5"
                  value={"New Admission"}
                  onChange={(e) => {
                    setPayType("COO PADS");
                  }}
                  checked={payType === "COO PADS"}
                />
                <label htmlFor="5" className="ml-2">
                  COO PADS
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="6"
                  value={"Subscription Renewal"}
                  onChange={(e) => {
                    setPayType("Attestation");
                  }}
                  checked={payType === "Attestation"}
                />

                <label htmlFor="6" className="ml-2">
                  Attestation
                </label>
              </div>
            </div>
            <div>
              <div
                style={{
                  textAlign: "left",
                  color: "red",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Balance RS. {formik.values.account_balance}
              </div>
              <div className="flex gap-4">
                <div className="flex align-items-center">
                  <div className="text-l align-items-center" style={styles}>
                    No. of Coo
                  </div>

                  <InputText
                    style={{
                      width: "50px",
                      border: "1px solid #000000",
                      borderRadius: "0px",
                      marginLeft: "2px",
                      textAlign: "left",
                      marginTop: "2px",
                      height: "30px",
                    }}
                    id="no_of_coo"
                    value={formik.values.no_of_coo}
                    onChange={formik.handleChange}
                  ></InputText>
                </div>
                {formik.values.coo_type === "Attestation" ||
                formik.values.coo_type === "COO" ||
                formik.values.is_special_case ? (
                  <div className="flex align-items-center">
                    <div className="text-l align-items-center" style={styles}>
                      Extra Pages
                    </div>

                    <InputText
                      style={{
                        width: "50px",
                        border: "1px solid #000000",
                        borderRadius: "0px",
                        marginLeft: "2px",
                        textAlign: "left",
                        marginTop: "2px",
                        height: "30px",
                      }}
                      id="no_of_pages"
                      value={formik.values.no_of_pages}
                      onChange={formik.handleChange}
                    ></InputText>
                  </div>
                ) : null}
              </div>
              {payType === "Visa Letter" ? (
                <Dropdown
                  options={durOptions}
                  id="no_of_days"
                  value={formik.values.no_of_days}
                  onChange={formik.handleChange}
                  placeholder="Select No. of Days"
                  style={{
                    width: "310px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "10px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                  }}
                />
              ) : null}
            </div>
          </div>
          <div
            className="card flex align-items-center gap-3"
            style={{
              marginTop: "10px",
              marginLeft: "2px",
            }}
          >
            <Checkbox
              onChange={(e) => setChecked(e.checked)}
              checked={checked}
            ></Checkbox>
            <div className="text-l align-items-center" style={styles}>
              Tatkal Service
            </div>
            <Checkbox
              onChange={(e) => setChecked2(e.checked)}
              checked={checked2}
            ></Checkbox>
            <div className="text-l align-items-center" style={styles}>
              Special Case
            </div>
          </div>
          <div className="flex gap-6">
            <div>
              <div className="flex align-items-center mt-2">
                <div
                  className="text-l align-items-center"
                  style={{
                    paddingRight: "5px",
                    marginTop: "2px",
                    textAlign: "right",
                    width: "150px",
                  }}
                >
                  Amount
                </div>

                <InputText
                  style={{
                    width: "260px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                    height: "30px",
                  }}
                  id="amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  disabled
                ></InputText>
              </div>
              {checked && payType !== "COO PADS" ? (
                <div className="flex align-items-center mt-2">
                  <div
                    className="text-l align-items-center"
                    style={{
                      paddingRight: "5px",
                      marginTop: "2px",
                      textAlign: "right",
                      width: "150px",
                    }}
                  >
                    Tatkal Amount
                  </div>

                  <InputText
                    style={{
                      width: "260px",
                      border: "1px solid #000000",
                      borderRadius: "0px",
                      marginLeft: "2px",
                      textAlign: "left",
                      marginTop: "2px",
                      height: "30px",
                    }}
                    id="tatkal_amount"
                    value={formik.values.tatkal_amount}
                    onChange={formik.handleChange}
                    disabled
                  ></InputText>
                </div>
              ) : null}
              <div className="flex align-items-center mt-2">
                <div
                  className="text-l align-items-center"
                  style={{
                    paddingRight: "5px",
                    marginTop: "2px",
                    textAlign: "right",
                    width: "150px",
                  }}
                >
                  SGST Amt (9%)
                </div>

                <InputText
                  style={{
                    width: "260px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                    height: "30px",
                  }}
                  id="sgst"
                  value={formik.values.sgst}
                  onChange={formik.handleChange}
                  disabled
                ></InputText>
              </div>
              <div className="flex align-items-center mt-2">
                <div
                  className="text-l align-items-center"
                  style={{
                    paddingRight: "5px",
                    marginTop: "2px",
                    textAlign: "right",
                    width: "150px",
                  }}
                >
                  CGST Amt (9%)
                </div>

                <InputText
                  style={{
                    width: "260px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                    height: "30px",
                  }}
                  id="cgst"
                  value={formik.values.cgst}
                  onChange={formik.handleChange}
                  disabled
                ></InputText>
              </div>
              <div className="flex align-items-center mt-2">
                <div
                  className="text-l align-items-center"
                  style={{
                    paddingRight: "5px",
                    marginTop: "2px",
                    textAlign: "right",
                    width: "150px",
                  }}
                >
                  IGST Amt (18%)
                </div>

                <InputText
                  style={{
                    width: "260px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                    height: "30px",
                  }}
                  id="igst"
                  value={formik.values.igst}
                  onChange={formik.handleChange}
                  disabled
                ></InputText>
              </div>

              <div className="flex align-items-center mt-2">
                <div
                  className="text-l align-items-center"
                  style={{
                    paddingRight: "5px",
                    marginTop: "2px",
                    textAlign: "right",
                    width: "150px",
                  }}
                >
                  Round off Amt
                </div>

                <InputText
                  style={{
                    width: "260px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                    height: "30px",
                  }}
                  id="round_off_amount"
                  value={formik.values.round_off_amount}
                  onChange={formik.handleChange}
                  disabled
                ></InputText>
              </div>
              <div className="flex align-items-center mt-2">
                <div
                  className="align-items-center"
                  style={{
                    paddingRight: "5px",
                    marginTop: "2px",
                    textAlign: "right",
                    width: "150px",
                    fontWeight: "bold",
                  }}
                >
                  Total Amount
                </div>
                <InputText
                  style={{
                    width: "260px",
                    border: "1px solid #000000",
                    borderRadius: "0px",
                    marginLeft: "2px",
                    textAlign: "left",
                    marginTop: "2px",
                    height: "30px",
                  }}
                  id="total_amount"
                  value={formik.values.total_amount}
                  onChange={formik.handleChange}
                  disabled={!checked2}
                ></InputText>
              </div>
            </div>
            <div className="flex flex-column gap-6 justify-content-end">
              <Button
                label="Save & Print"
                onClick={formik.handleSubmit}
                icon="pi pi-check"
                loading={isLoading}
                type="button"
                disabled={
                  formik.values.coo_type === "Visa Letter" &&
                  formik.values.no_of_days === ""
                }
              ></Button>
              {/* <Button label="Save Print" onClick={formik.handleSubmit} loading={isLoading} icon="pi pi-spin pi-spinner"/> */}
            </div>
          </div>
        </div>
      </Panel>
      <div style={{ display: "none" }}>
        <div ref={invoiceRef}>
          <Invoice data={invoiceData[0]} isDuplicate={false} />
          <Invoice data={invoiceData[0]} isDuplicate={true} />
        </div>
      </div>
    </div>
  );
};
