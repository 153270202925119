import "primeflex/primeflex.css";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import AdminAuthGuard from "./guards/AdminAuthGuard";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import CooLogin from "./pages/CooLogin";
import { Coo } from "./site/admin/CooManagement/components/Coo/Coo";
import CooManagement from "./site/admin/CooManagement/CooManagement";
import { Reports } from "./site/admin/CooManagement/components/Reports/Reports";
import { Balance } from "./site/admin/CooManagement/components/Balance/Balance";
import { Receipts } from "./site/admin/CooManagement/components/Receipts/Receipts";
import useGlobalKeyPress from "./site/admin/useGlobalKeyPress";
import { useNavigate } from "react-router-dom";

const customHistory = createBrowserHistory();


// Use the custom hook to listen for "Ctrl + D"
function App() {
  const navigate = useNavigate();
  const handleCtrlD = () => {

    // Redirect to the desired page
    navigate('/cooManagement/coo');
  
    // Focus on the select input (you might need to use a ref)
    // Example: const selectRef = useRef(null);
    // selectRef.current.focus();
  };
  useGlobalKeyPress('x', handleCtrlD);
  const userType = window.localStorage.getItem("userType");
  return (
    <div className="App">
      <Provider store={store}>
        <Routes>
          <Route path="">
            <Route index={true} element={<CooLogin />} />
            <Route
              path="cooManagement"
              element={<AdminAuthGuard children={<CooManagement />} />}
            >
              {userType === "COO User"&&
              <Route
                path="coo"
                element={<AdminAuthGuard children={<Coo />} />}
              />}
              <Route
                path="reports"
                element={<AdminAuthGuard children={<Reports />} />}
              />
              {userType === "Account Team"&&<Route
                path="account-balance"
                element={<AdminAuthGuard children={<Balance />} />}
              />}
              <Route
                path="receipts"
                element={<AdminAuthGuard children={<Receipts />} />}
              />
            </Route>
          </Route>
        </Routes>
      </Provider>
    </div>
  );
}

export default App;
