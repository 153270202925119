// Create a custom hook for global key press events
import { useEffect } from 'react';

const useGlobalKeyPress = (targetKey, callback) => {
    const userType = window.localStorage.getItem("userType");
    useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.key === targetKey) {
        callback();
      }
    };
    if(userType ==='COO User'){

    // Add event listener when the component mounts
    window.addEventListener('keydown', handleKeyPress);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
};

  }, [targetKey, callback]);
}

export default useGlobalKeyPress;
