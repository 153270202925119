import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { useEffect, useRef, useState } from "react";
import { cancelReceipt, getAllCooReceipts, getCOOReceipts } from "../../../../../apis/api";
import { Dialog } from "primereact/dialog";
import { RePrintInvoice } from "./RePrintInvoice";
import { useReactToPrint } from "react-to-print";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import moment from "moment";
export const Receipts = () => {
  const userType = window.localStorage.getItem("userType");

  const invoiceRef = useRef(null);
  const handleMemberPrint = useReactToPrint({
    content: () => invoiceRef.current,
  });

  const toast = useRef(null);


  const [data, setData] = useState([]);

  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [singleReceipt, setSingleReceipt] = useState({});
  const [displayBasic2, setDisplayBasic2] = useState(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    getAllCooReceipts(currentPage, 50)
      .then((res) => {
        console.log("ssss", res);
        setData(res.data.results);
        setTotalRecords(res.data.total);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [currentPage,update]);
  const onBasicPageChange = (event) => {
    console.log("event", event);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setCurrentPage(event.page);
  };

  const receiptNo = (data) => {
    return (
      <div
        onClick={() => {
          console.log("Button", data);
          setSingleReceipt(data);
          setDisplayBasic2(true);
        }}
      >
        {data.receipt_no}
      </div>
    );
  };
  const cancelStatus = (data)=>{
    return(
      (data.cancel_status?
       <p>cancelled</p> 
        :<p>not-cancelled</p>)
    )
  }
  const createdAt = (data)=>{
    return(
      <p>{moment(data?.created_at).format("DD-MM-YYYY")}</p>
    )
  }
  console.log(singleReceipt, "kjhhjkjk");
 const cancelCooReceipt = (receipt)=>{
  cancelReceipt(receipt.id).then((res)=>{
    if(res.status === 200){
      setDisplayBasic2(false)
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Cancelled",
        life: 3000,
      });
      setUpdate(true);
    }
  })
}
  return (
    <>
      <div className="m-3">
      <Toast ref={toast}></Toast>

        <div className="card">
        <DataTable value={data} showGridlines selectionMode={"checkbox"} size={'small'} >
          <Column field={receiptNo} header="Invoice No." className="custom-column"></Column>
          <Column field="member_name_of_org" header="Name of Organization" className="custom-column"></Column>
          <Column field="coo_type" header="Coo Type" className="custom-column"></Column>
          <Column field="no_of_coo" header="No Of Coo" className="custom-column"></Column>
          <Column field={createdAt} header="Date of Invoice" className="custom-column"></Column>
          <Column field={cancelStatus} header="Invoice Status" className="custom-column"></Column>
        </DataTable>
          <Paginator
            first={basicFirst}
            totalRecords={totalRecords}
            rows={basicRows}
            onPageChange={onBasicPageChange}
          ></Paginator>
          <Dialog
            header="Report Details"
            visible={displayBasic2}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "60vw" }}
            // footer={renderUploadFooter("displayBasic2")}
            onHide={() => setDisplayBasic2(false)}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "end",
                paddingRight: "20px",
              }}
            >
              
              {userType === "Account Team" ? (
  singleReceipt.cancel_status ? (
    <Button>Cancelled</Button>
  ) : (
    <>
    <Button onClick={handleMemberPrint}>Reprint</Button>
    <Button onClick={() => cancelCooReceipt(singleReceipt)}>Cancel</Button>
    </>
  )
) : 
(
  singleReceipt.cancel_status ? (
    <Button>Cancelled</Button>
  ) : (
    <>
    <Button onClick={handleMemberPrint}>Reprint</Button>
    </>
  )
)}
            </div>
            <div ref={invoiceRef}>
              <RePrintInvoice data={singleReceipt} isDuplicate={false}/>
              <RePrintInvoice data={singleReceipt} isDuplicate={true}/>
            </div>
          </Dialog>
        </div>
      </div>
    </>
  );
};
