import React from "react";
import { useNavigate, Navigate } from "react-router-dom";

const CheckIsAdmin = () => {
  if (window.localStorage.getItem("userType")) {
    if (!(window.localStorage.getItem("userType") === "FKCCI Member")) {
      return true;
    }
    return false;
  }
};
console.log('check',window.localStorage.getItem("userType"))
function AdminAuthGuard({ children }) {
  return <div>{CheckIsAdmin() ? children : <Navigate to="/" />}</div>;
}

export default AdminAuthGuard;
