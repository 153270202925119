import moment from "moment";
import React from "react";
// import Logo from "../../../../../../assets/logo1.png";
import Logo from "../../../../../assets/logo1.png";
// import Signature from "../../../../../../assets/signature.png";

import "./invoice.css";
var converter = require("number-to-words");
function DownloadAmountReport({ data}) {
    console.log(data, 'find a way')
  const getYear = (val) => {
    // const date = new Date(val);
    // const year = date.getFullYear();
    return val;
  };
  const getNextYear = (val) => {
    // const date = new Date(val);
    const year = Number(val) + 1;
    return year;
  };
  const getCurrency = (val) => {
    const curr = new Intl.NumberFormat().format(val);
    const total = curr.toString() + ".00";
    return total;
  };
  // console.log("invoiceData", data);
  function capitalizeFirstLetter(string) {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  function convertNumberToWords(number) {
    const wordsWithHyphen = converter.toWords(number);
    const wordsWithoutHyphen = wordsWithHyphen.replace(/-/g, " ");
    return wordsWithoutHyphen;
  }
  return (
    <div
      className=""
      style={{
        width: "100%",
        padding:  "25px 25px",
        marginTop:  "0",

      }}
    >
      <div
        style={{
          border: "1px solid #000000",
          display: "flex",
          // justifyContent: "space-between",
          gap: "50px",
          borderRadius: "5px",
          padding: "10px 20px 5px 20px",
        }}
      >
        <div>
          <img src={Logo} alt="Logo" style={{ height: "70px" }} />
        </div>
        <div>
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              lineHeight: "18px",
              fontWeight: "600",
            }}
          >
            FEDERATION OF KARNATAKA CHAMBERS OF COMMERCE & INDUSTRY
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              className="textLeft2"
              style={{ fontSize: "11px", marginTop: "6px" }}
            >
              CIN No: U91110KA1938NPL000244
            </p>
            <p className="Text">FEDERATION HOUSE</p>
            <p
              className="textLeft2"
              style={{ fontSize: "11px", marginTop: "6px" }}
            >
              GSTIN: 29AAACF2404N1ZM
            </p>
          </div>
          <p
            style={{
              fontSize: "12px",
              textAlign: "center",
              lineHeight: "16px",
            }}
          >
            P.B.No.9996, Kempegowda Road, Bengaluru - 560009 | Ph: +91 080 2226
            2355/56
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "30px",
              // borderRadius: "5px",
              // padding: "10px 20px 5px 20px",
            }}
          >
            <p className="textLeft2" style={{ marginTop: "5px" }}>
              PAN No.: AAACF2404N
            </p>
            <p className="Text" style={{ fontSize: "11px" }}>
              e-mail:certificateoforigin@fkcci.in
            </p>
          </div>
          <p
            style={{
              fontSize: "18px",
              textAlign: "center",
              lineHeight: "24px",
              fontWeight: "500",
            }}
          >
            TAX INVOICE
          </p>
        </div>
      </div>
      <div
        style={{
          border: "1px solid #000000",
          borderRadius: "5px",
          padding: "10px 20px",
          minHeight:'400px',

        }}
      >
        {/* <p className="Text">Place of Supply:29-Karnataka</p> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px dashed #000",
            paddingBottom: "2px",
          }}
        >
          <p style={{ fontSize: "12px" }}>Invoice No. : {data?.invoice_no}</p>
          <div style={{ display: "flex", marginRight: "20px" }}>
            <p style={{ fontSize: "11px" }}>
              Date : {moment(data?.created_at).format("DD-MM-YYYY")}
            </p>
            {/* <p style={{ fontSize: "11px", marginLeft: "20px" }}>
              Time: {moment(data?.created_at).format("hh:mm A")}
            </p> */}
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px dashed #000",
            margin: "3px 0",
            paddingBottom: "5px",
          }}
        >
          <p style={{ fontSize: "11px", fontWeight: "bold" }}>Received from</p>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "60%" }}>
            <p className="textLeft" style={{ fontSize: "11px" }}>
              {data?.member_name_of_org}
            </p>
            <p
              className="textLeft"
              style={{ marginTop: "5px", fontSize: "11px" }}
            >
              {data?.address}
              {/* {data?.member?.city}-{data?.member?.pincode} */}
            </p>
          </div>
          <div
            style={{
              marginRight: "30px",
              paddingLeft: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            {/* <p style={{ fontSize: "14px" }}>{data?.MEM_id_no}</p> */}
            <p style={{ fontSize: "11px" }}>
              Customer GST NO.{data?.member_gst_no}
            </p>
            <p className="Text" style={{ marginTop: "3px", fontSize: "11px" }}>
              Place of Supply : 29-Karnataka
            </p>

            <p className="Text" style={{ marginTop: "3px", fontSize: "11px" }}>
by Cash
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            border: "1px solid #000",
            padding: "10px 20px",
            marginTop: "20px",
            borderRadius: "8px",
          }}
        >
          <div style={{ display: "flex" ,margin:'5px'}}>
            <p className="textLeft" style={{ fontSize: "12px" }}>
              Sl. No.
            </p>
            <p
              className="textLeft"
              style={{ marginLeft: "100px", fontSize: "12px" }}
            >
              DESCRIPTION
            </p>
          </div>
          <p
            className="textLeft"
            style={{ marginRight: "20px", fontSize: "12px" }}
          >
            AMOUNT IN RS.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <div>
            <div style={{ display: "flex" }}>
              <p
                className="textLeft"
                style={{ marginLeft: "20px", fontSize: "12px" }}
              >
                1
              </p>
              <p
                className="textLeft"
                style={{ marginLeft: "50px", fontSize: "12px" }}
              >
               Advance For COO
              </p>
            </div>
            <p
              className="textLeft"
              style={{
                marginLeft: "100px",
                marginTop: "50px",
                fontSize: "12px",
              }}
            >
              HSN Code : 998214
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              alignItems: "end",
              marginRight: "60px",
              fontSize: "12px",
            }}
          >
            {/* {data?.pay_mode==="Account"?(
         <div style={{display:"flex",}}>
         <p className="Text" style={{fontWeight:"bold", fontSize:"12px"}}>
            OPENING BALANCE : 
           </p>
           <p className="Text" style={{marginLeft:"50px",marginRight:"0px",fontWeight:"bold", fontSize:"12px"}}>
            0.00
           </p>
           </div>
    ):null} */}
            <p className="Text" style={{ fontSize: "12px" }}>
              {data?.credit}
            </p>
            {/* <div style={{ display: "flex", marginLeft: "60px" }}>
              <p
                className="Text"
                style={{ marginRight: "-50px", fontSize: "12px" }}
              >
                Tatkal Amount :
              </p>
              <p
                className="Text"
                style={{
                  marginLeft: "50px",
                  borderBottom: "1px solid #000",
                  padding: "5px 50px",
                  marginRight: "-50px",
                  fontSize: "12px",
                }}
              >
                {data?.tatkal_amount}
              </p>
            </div>
            <div
              style={{ display: "flex", marginTop: "5px", fontSize: "12px" }}
            >
              <p className="Text" style={{ fontSize: "12px" }}>
                Taxable Value
              </p>
              <p
                className="Text"
                style={{ marginLeft: "50px", fontSize: "12px" }}
              >
                {Number(
                  Number(data?.amount) + Number(data?.tatkal_amount)
                ).toFixed(2)}
              </p>
            </div> */}
              <div
                style={{ display: "flex", marginTop: "3px", fontSize: "12px" }}
              >
                <p className="Text" style={{ fontSize: "12px" }}>
                  SGST @9.00%
                </p>

                <p
                  className="Text"
                  style={{ marginLeft: "50px", fontSize: "12px" }}
                >
                  0.00
                </p>
              </div>

              <div style={{ display: "flex", fontSize: "12px" }}>
                <p className="Text" style={{ fontSize: "12px" }}>
                  CGST @9.00%
                </p>
                <p
                  className="Text"
                  style={{ marginLeft: "50px", fontSize: "12px" }}
                >
                 0.00
                </p>
              </div>
              <div style={{ display: "flex", fontSize: "12px" }}>
                <p className="Text" style={{ fontSize: "12px" }}>
                  IGST @18.00%
                </p>
                <p
                  className="Text"
                  style={{ marginLeft: "50px", fontSize: "12px" }}
                >
                  0.00
                </p>
              </div>
            
            <p
              className="Text"
              style={{
                marginTop: "5px",
                padding: "1px 50px",
                borderTop: "1px solid #000",
                borderBottom: "1px solid #000",
                marginRight: "-50px",
                fontSize: "12px",
              }}
            >
            {data?.credit}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems:'end',
            width: "70%",
          }}
        >
          {/* <p className="Text" style={{ fontWeight: "700" }}>
            Rs.{getCurrency(data?.total_amount)}
          </p> */}
        </div>
        <p className="textLeft" style={{ fontSize: "12px", marginTop: "5px" }}>
          Amount in words :{" "}
          {capitalizeFirstLetter(convertNumberToWords(data?.credit ?? 0))}{" "}
          Only
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            // marginTop: "10px",
          }}
        >
          {/* <p className="textLeft" style={{}}>
            Remarks:
          </p> */}
          {/* <img
            src={Signature}
            alt="Logo"
            style={{
              height: "80px",
              marginRight: "40px",
            }}
          /> */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            // marginTop: "5px",
          }}
        >
          <p
            className="Text"
            style={{ fontSize: "12px", marginRight: "20px", marginTop: "5px" }}
          >
            Authorised Signatory
          </p>
        </div>
      </div>
    </div>
  );
}

export default DownloadAmountReport;
