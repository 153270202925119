import React, { useState, useRef, useEffect } from "react";
import { TabMenu } from "primereact/tabmenu";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import AdminMenuBar from "../AdminMenuBar";
import { useDispatch } from "react-redux";
import { setRefresh } from "../../../redux/member/memberReducer";

function CooManagement() {
  const [isLoading, setLoading] = useState(false);
  const userType = window.localStorage.getItem("userType");
  const navigate = useNavigate();
  let location = useLocation();
  console.log(location.pathname.split("/")[2]);
  const [activeIndex, setActiveIndex] = useState(0);
  const toast = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.pathname.split("/")[2] === "coo") {
      setActiveIndex(0);
    }
  }, [location.pathname]);
  useEffect(() => {
    setLoading(false);
  }, [isLoading]);
  const items = [
    {
      label: "COO",
      icon: "pi pi-fw pi-home",
    },
    {
      label: "Reports",
      icon: "pi pi-fw pi-home",
    },
    {
      label: "Coo-receipts",
      icon: "pi pi-fw pi-bars",
    },
  ];
  const items2 = [
    {
      label: "Account Balance",
      icon: "pi pi-fw pi-home",
    },
    {
      label: "Reports",
      icon: "pi pi-fw pi-home",
    },
    {
      label: "Coo-receipts",
      icon: "pi pi-fw pi-bars",
    },
  ];
  return (
    <div>
      <AdminMenuBar />
      <TabMenu
        activeIndex={activeIndex}
        onTabChange={(e) => {
          if (e.index === 0) {
            setActiveIndex(e.index);
            setLoading(true);
            navigate(userType === "COO User" ? "coo" : "account-balance");
          }
          if (e.index === 1) {
            setActiveIndex(e.index);
            setLoading(true);
            navigate("reports");
          }
          if (e.index === 2) {
            setActiveIndex(e.index);
            setLoading(true);
            navigate("receipts");
          }
        }}
        className="m-3"
        model={userType === "COO User" ? items : items2}
      />
      {isLoading && (
        <div>
          <div>Loading</div>
          <div>
            <ProgressSpinner className="h-1rem" strokeWidth={3} />
          </div>
        </div>
      )}
      {!isLoading && <Outlet setLoading={setLoading} />}
    </div>
  );
}

export default CooManagement;
