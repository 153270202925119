import moment from "moment";
import React from "react";
import Logo from "../../../../../../assets/logo1.png";
import Signature from "../../../../../../assets/signature.png";

import "./Invoice.css";
var converter = require("number-to-words");
function Invoice({ data, isDuplicate, accountBalance }) {
  // const gstno = (data?.member_gst_no).gstno.substring(0, 2);
  
  const getYear = (val) => {
    // const date = new Date(val);
    // const year = date.getFullYear();
    return val;
  };
  const getNextYear = (val) => {
    // const date = new Date(val);
    const year = Number(val) + 1;
    return year;
  };
  const getCurrency = (val) => {
    const curr = new Intl.NumberFormat().format(val);
    const total = curr.toString() + ".00";
    return total;
  };
  // console.log("invoiceData", data);
  function capitalizeFirstLetter(string) {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  function convertNumberToWords(number) {
    const wordsWithHyphen = converter.toWords(number);
    const wordsWithoutHyphen = wordsWithHyphen.replace(/-/g, " ");
    return wordsWithoutHyphen;
  }
  return (
    <div
      className=""
      style={{
        width: "100%",
        padding: isDuplicate ? "25px 25px" : "25px 25px",
        marginTop: isDuplicate ? "700px" : "0",
        color: "#888888"
      }}
    >
      <div
        style={{
          border: "1px solid #888888",
          display: "flex",
          // justifyContent: "space-between",
          gap: "50px",
          borderRadius: "5px",
          padding: "10px 20px 5px 20px",
        }}
      >
        <div>
          <img src={Logo} alt="Logo" style={{ height: "70px" }} />
        </div>
        <div>
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              lineHeight: "18px",
              fontWeight: "600",
            }}
          >
            FEDERATION OF KARNATAKA CHAMBERS OF COMMERCE & INDUSTRY
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              className="textLeft2"
              style={{ fontSize: "11px", marginTop: "6px" }}
            >
              CIN No: U91110KA1938NPL000244
            </p>
            <p className="Text">FEDERATION HOUSE</p>
            <p
              className="textLeft2"
              style={{ fontSize: "11px", marginTop: "6px" }}
            >
              GSTIN: 29AAACF2404N1ZM
            </p>
          </div>
          <p
            style={{
              fontSize: "12px",
              textAlign: "center",
              lineHeight: "16px",
            }}
          >
            P.B.No.9996, Kempegowda Road, Bengaluru - 560009 | Ph: +91 080 2226
            2355/56
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "30px",
              // borderRadius: "5px",
              // padding: "10px 20px 5px 20px",
            }}
          >
            <p className="textLeft2" style={{ marginTop: "5px" }}>
              PAN No.: AAACF2404N
            </p>
            <p className="Text" style={{ fontSize: "11px" }}>
              e-mail:certificateoforigin@fkcci.in
            </p>
          </div>
          <p
            style={{
              fontSize: "18px",
              textAlign: "center",
              lineHeight: "24px",
              fontWeight: "500",
            }}
          >
            {isDuplicate
              ? "TAX INVOICE (Duplicate for Supplier)"
              : "TAX INVOICE"}
          </p>
        </div>
      </div>
      <div
        style={{
          border: "1px solid #888888",
          borderRadius: "5px",
          padding: "10px 20px",
        }}
      >
        {/* <p className="Text">Place of Supply:29-Karnataka</p> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px dashed #888",
            paddingBottom: "2px",
          }}
        >
          <p style={{ fontSize: "12px" }}>Receipt No. : {data?.receipt_no}</p>
          <div style={{ display: "flex", marginRight: "20px" }}>
            <p style={{ fontSize: "11px" }}>
              Date : {moment(data?.created_at).format("DD-MM-YYYY")}
            </p>
            <p style={{ fontSize: "11px", marginLeft: "20px" }}>
              Time: {moment(data?.created_at).format("hh:mm A")}
            </p>
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px dashed #888",
            margin: "3px 0",
            paddingBottom: "5px",
          }}
        >
          <p style={{ fontSize: "11px", fontWeight: "bold" }}>Received from</p>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "60%" }}>
            <p className="textLeft" style={{ fontSize: "11px" }}>
              {data?.member_name_of_org}
            </p>
            <p
              className="textLeft"
              style={{ marginTop: "5px", fontSize: "11px" }}
            >
              {data?.member_address},
              {/* {data?.member?.city}-{data?.member?.pincode} */}
            </p>
            <p
              className="textLeft"
              style={{ marginTop: "5px", fontSize: "11px" }}
            >
             
              {data?.city},{" "}
              {data?.pincode},{" "}
              {data?.state}
              {/* {data?.member?.city}-{data?.member?.pincode} */}
            </p>
            <p className="textLeft"
              style={{ marginTop: "5px", fontSize: "11px" }}>
            {data?.phone_number}
            </p>
          </div>
          <div
            style={{
              marginRight: "30px",
              paddingLeft: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            {/* <p style={{ fontSize: "14px" }}>{data?.MEM_id_no}</p> */}
            <p style={{ fontSize: "11px" }}>
              Customer GST NO.{data?.member_gst_no}
            </p>
            <p className="Text" style={{ marginTop: "3px", fontSize: "11px" }}>
              Place of Supply : {getStateDataByGSTCode(data?.member_gst_no)}
            </p>

            <p className="Text" style={{ marginTop: "3px", fontSize: "11px" }}>
              Tax Payable on Reverse Charge: No
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            border: "1px solid #888",
            padding: "0px 20px",
            marginTop: "8px",
            borderRadius: "8px",
          }}
        >
          <div style={{ display: "flex" ,margin:'5px'}}>
            <p className="textLeft" style={{ fontSize: "12px"}}>
              Sl. No.
            </p>
            <p
              className="textLeft"
              style={{ marginLeft: "100px", fontSize: "12px" }}
            >
              DESCRIPTION
            </p>
          </div>
          <p
            className="textLeft"
            style={{ marginRight: "20px", fontSize: "12px"  ,paddingTop:'2px'}}
          >
            AMOUNT IN RS.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <div>
            <div style={{ display: "flex" }}>
              <p
                className="textLeft"
                style={{ marginLeft: "20px", fontSize: "12px" }}
              >
                1
              </p>
              <p
                className="textLeft"
                style={{ marginLeft: "50px", fontSize: "12px" }}
              >
                {data?.coo_type === "COO"?
               ` Certificate of Origin(${data?.no_of_coo}(Extra Pages:${" "}
                ${data?.no_of_pages}))`
                :data?.coo_type === "Visa Letter"?
                ` Visa Recommendation Letter(${data?.no_of_coo}(Extra Pages:${" "}
                 ${data?.no_of_pages}))`
                :`${data?.coo_type}(${data?.no_of_coo}(Extra Pages:${" "}
                ${data?.no_of_pages}))`
                }
                
              </p>
            </div>
            <p
              className="textLeft"
              style={{
                marginLeft: "100px",
                marginTop: "50px",
                fontSize: "12px",
              }}
            >
              HSN Code : 998214
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              alignItems: "end",
              marginRight: "60px",
              fontSize: "12px",
            }}
          >
            {data?.pay_mode==="Account"?(
         <div style={{display:"flex",}}>
         <p className="Text" style={{fontWeight:"bold", fontSize:"12px"}}>
            OPENING BALANCE : 
           </p>
           <p className="Text" style={{marginLeft:"50px",marginRight:"0px",fontWeight:"bold", fontSize:"12px"}}>
           {data?.opening_balance}.00
           </p>
           </div>
    ):null} 
            <p className="Text" style={{ fontSize: "12px" }}>
              {data?.amount}
            </p>
            <div style={{ display: "flex", marginLeft: "60px" }}>
              <p
                className="Text"
                style={{ marginRight: "-50px", fontSize: "12px" }}
              >
                Tatkal Amount :
              </p>
              <p
                className="Text"
                style={{
                  marginLeft: "50px",
                  borderBottom: "1px solid #000",
                  padding: "5px 50px",
                  marginRight: "-50px",
                  fontSize: "12px",
                }}
              >
                {data?.tatkal_amount}
              </p>
            </div>
            <div
              style={{ display: "flex", marginTop: "5px", fontSize: "12px" }}
            >
              <p className="Text" style={{ fontSize: "12px" }}>
                Taxable Value
              </p>
              <p
                className="Text"
                style={{ marginLeft: "50px", fontSize: "12px" }}
              >
                {Number(
                  Number(data?.amount) + Number(data?.tatkal_amount)
                ).toFixed(2)}
              </p>
            </div>
            {data?.sgst !== "0.00" ? (
              <div
                style={{ display: "flex", marginTop: "3px", fontSize: "12px" }}
              >
                <p className="Text" style={{ fontSize: "12px" }}>
                  SGST @9.00%
                </p>

                <p
                  className="Text"
                  style={{ marginLeft: "50px", fontSize: "12px" }}
                >
                  {data?.sgst}
                </p>
              </div>
            ) : null}

            {data?.cgst !== "0.00" ? (
              <div style={{ display: "flex", fontSize: "12px" }}>
                <p className="Text" style={{ fontSize: "12px" }}>
                  CGST @9.00%
                </p>
                <p
                  className="Text"
                  style={{ marginLeft: "50px", fontSize: "12px" }}
                >
                  {data?.cgst}
                </p>
              </div>
            ) : null}
            {data?.igst !== "0.00" ? (
              <div style={{ display: "flex", fontSize: "12px" }}>
                <p className="Text" style={{ fontSize: "12px" }}>
                  IGST @18.00%
                </p>
                <p
                  className="Text"
                  style={{ marginLeft: "50px", fontSize: "12px" }}
                >
                  {data?.igst}
                </p>
              </div>
            ) : null}
            <div
              style={{ display: "flex", marginTop: "5px", fontSize: "12px" }}
            >
              <p className="Text" style={{ fontSize: "12px" }}>
                Round Off
              </p>
              <p
                className="Text"
                style={{ marginLeft: "50px", fontSize: "12px" }}
              >
                {data?.round_off_amount}
              </p>
            </div>
            <p
              className="Text"
              style={{
                marginTop: "5px",
                padding: "1px 50px",
                borderTop: "1px solid #000",
                borderBottom: "1px solid #000",
                marginRight: "-50px",
                fontSize: "12px",
              }}
            >
              {data?.total_amount}
            </p>
             {data?.pay_mode==="Account"?(
         <div style={{display:"flex", paddingTop:'5px'}}>
         <p className="Text" style={{fontWeight:"bold", fontSize:"12px"}}>
            CLOSING BALANCE : 
           </p>
           <p className="Text" style={{marginLeft:"50px",marginRight:"0px",fontWeight:"bold", fontSize:"12px"}}>
           {data?.closing_balance}.00
           </p>
           </div>
   ):null} 
          </div>
         
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "70%",
          }}
        >
          {/* <p className="Text" style={{ fontWeight: "700" }}>
            Rs.{getCurrency(data?.total_amount)}
          </p> */}
        </div>
        <p className="textLeft" style={{ fontSize: "12px", marginTop: "5px" }}>
          Amount in words :{" "}
          {capitalizeFirstLetter(convertNumberToWords(data?.total_amount ?? 0))}{" "}
          Only
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            // marginTop: "10px",
          }}
        >
          {/* <p className="textLeft" style={{}}>
            Remarks:
          </p> */}
          {/* <img
            src={Signature}
            alt="Logo"
            style={{
              height: "80px",
              marginRight: "40px",
            }}
          /> */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            // marginTop: "5px",
          }}
        >
          <p
            className="Text"
            style={{ fontSize: "12px", marginRight: "20px", marginTop: "5px" }}
          >
            Authorised Signatory
          </p>
        </div>
      </div>
    </div>
  );
}
const gstCodeData = [
  {"state":"Himachal Pradesh","gst_state_code":"2","Alpha_state_code":"HP"},
  {"state":"Punjab","gst_state_code":"3","Alpha_state_code":"PB"},
  {"state":"Chandigarh","gst_state_code":"4","Alpha_state_code":"CH"},
  {"state":"Uttarakhand","gst_state_code":"5","Alpha_state_code":"UA"},
  {"state":"Haryana","gst_state_code":"6","Alpha_state_code":"HR"},
  {"state":"Delhi","gst_state_code":"7","Alpha_state_code":"DL"},
  {"state":"Rajasthan","gst_state_code":"8","Alpha_state_code":"RJ"},
  {"state":"Uttar Pradesh","gst_state_code":"9","Alpha_state_code":"UP"},
  {"state":"Bihar","gst_state_code":"10","Alpha_state_code":"BR"},
  {"state":"Sikkim","gst_state_code":"11","Alpha_state_code":"SK"},
  {"state":"Arunanchal Pradesh","gst_state_code":"12","Alpha_state_code":"AP"},
  {"state":"Nagaland","gst_state_code":"13","Alpha_state_code":"NL"},
  {"state":"Manipur","gst_state_code":"14","Alpha_state_code":"MN"},
  {"state":"Mizoram","gst_state_code":"15","Alpha_state_code":"MZ"},
  {"state":"Tripura","gst_state_code":"16","Alpha_state_code":"TR"},
  {"state":"Meghalaya","gst_state_code":"17","Alpha_state_code":"ML"},
  {"state":"Assam","gst_state_code":"18","Alpha_state_code":"AS"},
  {"state":"West Bengal","gst_state_code":"19","Alpha_state_code":"WB"},
  {"state":"Jharkhand","gst_state_code":"20","Alpha_state_code":"JH"},
  {"state":"Odisha","gst_state_code":"21","Alpha_state_code":"OR"},
  {"state":"Chattisgarh","gst_state_code":"22","Alpha_state_code":"CG"},
  {"state":"Madhya Pradesh","gst_state_code":"23","Alpha_state_code":"MP"},
  {"state":"Gujarat","gst_state_code":"24","Alpha_state_code":"GJ"},
  {"state":"Dadra And Nagar Haveli And Daman And� Diu�","gst_state_code":"26","Alpha_state_code":"DD,DN"}
  ,
  {"state":"Maharashtra","gst_state_code":"27","Alpha_state_code":"MH"},
  {"state":"Andhra Pradesh","gst_state_code":"28","Alpha_state_code":"AP"},
  {"state":"Karnataka","gst_state_code":"29","Alpha_state_code":"KA"},
  {"state":"Goa","gst_state_code":"30","Alpha_state_code":"GA"},
  {"state":"Lakshadweep","gst_state_code":"31","Alpha_state_code":"LD"},
  {"state":"Kerela","gst_state_code":"32","Alpha_state_code":"KL"},
  {"state":"Tamil Nadu","gst_state_code":"33","Alpha_state_code":"TN"},
  {"state":"Puducherry","gst_state_code":"34","Alpha_state_code":"PY"},
  {"state":"Andaman and Nicobar Islands","gst_state_code":"35","Alpha_state_code":"AN"},
  {"state":"Telangana","gst_state_code":"36","Alpha_state_code":"TS"},
  {"state":"Andhra Pradesh","gst_state_code":"37","Alpha_state_code":"AP"},
  {"state":"Ladakh","gst_state_code":"38","Alpha_state_code":"LA"},
  {"state":"Other Territory","gst_state_code":"97","Alpha_state_code":"0T"}
  ]
  function getStateDataByGSTCode(gstCode) {
    const gstNum = gstCode?.substring(0, 2);

    for (const stateObj of gstCodeData) {
      if (stateObj.gst_state_code === gstNum) {
        return stateObj.gst_state_code + "-" + stateObj.state
        
      }
    }
    return null; // Return null if no match is found
  }
export default Invoice;
