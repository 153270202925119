import axios from "axios";
const login = async (email, password) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/login`,
    { email, password },
    { headers: { "Content-Type": "application/json" } }
  );
  return response.data;
};
const loginMember = async (email, password) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/memberships/login`,
    { email, password },
    { headers: { "Content-Type": "application/json" } }
  );
  return response.data;
};
const logout = async () => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/logout`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    }
  );
  if (response.status === 401) {
    window.location.replace("/");
    return null;
  } else {
    return response;
  }
};

const getSearchMember = async (key) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/search/cert-of-origin?key=${key}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const registerCOO = async (data) => {
  try {
    console.log("res", data);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/cert-of-origin`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return error;
    }
  }
};
const addBalance = async (data) => {
  try {
    console.log("res", data);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/coo-accounts-addBalance`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );

    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return error;
    }
  }
};
const getCOOReceipts = async (url) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/cert-of-origin/receipts?${url}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const getAllCooReceipts = async (pageNumber, pageSize) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/cert-of-origin/AllReceipts?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
const cancelReceipt = async (id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/memberships/${id}/cooReceipt`,{},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/");
      return null;
    }
  }
};
export {
  login,
  loginMember,
  logout,
  registerCOO,
  getCOOReceipts,
  getSearchMember,
  addBalance,
  getAllCooReceipts,
  cancelReceipt
};
