import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import {
  addBalance,
  getSearchMember,
  registerCOO,
} from "../../../../../apis/api";
import { Toast } from "primereact/toast";
import DownloadAmountReport from "./DownloadAmountReport";
import { useReactToPrint } from "react-to-print";
import Select from 'react-select';
export const Balance = () => {

  const BalanceCreditRef = useRef(null);

  const [compony, setCompony] = useState("");
  const [address, setAddress] = useState("");
  const [gstIn, setGstIn] = useState("");
  const [memberType, setMemberType] = useState("Members");
  const [search, setSearch] = useState("");
  const [oldBalance, setOldBalance] = useState("0.00");
  const [balance, setBalance] = useState("");
  const [member_id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [creditData, setCreditData] = useState(false);


  const [data, setData] = useState([{}]);
  const toast = useRef(null);

  const bankOptionTemplate = (option) => {
    return (
      <div className="flex flex-column align-items-center">
        <div>{option.name_of_org}</div>
      </div>
    );
  };
  const selectedBankTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name_of_org}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const handleMemberPrint = useReactToPrint({
    content: () => BalanceCreditRef.current,
  });

  const searchFunction = async (value) => {
    console.log(value, 'jhgfdfg')
    await getSearchMember(value)
      .then((res) => {
        console.log("search", res);
        if (res === null || res.length === 0) {
          setData([{}]);
        } else {
          setData(res);
        }
        console.log("searchhhhhhcanca ", search);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const addAmount = async () => {
    if (member_id === "") {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Select a member first!",
        life: 3000,
      });
    } else if (balance === "") {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Enter amount first!",
        life: 3000,
      });
    } else {
      setLoading(true);
      try {
        const data = {
          member_id,
          amount: Number(balance),
        };
        const res = await addBalance(data);
        if (res.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Done",
            detail: "Amount credited successfully!",
            life: 3000,
          });
          console.log(res.data[0], 'findeee');
          setCreditData(res.data[0]);
          setTimeout(() => {
            handleMemberPrint();
            setLoading(false);
          }, 1000);
          setCompony("");
          setGstIn("");
          setAddress("");
          setOldBalance("0.00");
          setId("");
          setBalance("");
          setData([{}]);
          setSearch("");
        }

      } catch (error) {
        console.log("error", error);
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Amount couldn't be credited!",
          life: 3000,
        });
      }
    }
  };
  return (
    <div className="justify-content-center align-items-center flex p-8">
      <Toast ref={toast}></Toast>
      <Panel header="Account Balance">
        <div>
          <div className="flex align-items-start">
            <div
              className="text-l align-items-center"
              style={{
                paddingRight: "5px",
                marginTop: "2px",
                textAlign: "right",
                width: "200px",
              }}
            >
              Member Name
            </div>

            {/* <Dropdown
              style={{
                width: "454px",
                border: "1px solid #000000",
                borderRadius: "0px",
                marginLeft: "2px",
                textAlign: "left",
                marginTop: "2px",
                height: "40px",
              }}
              id="member_name_of_org"
              value={compony}
              onChange={(e) => {
                setCompony(e.value);
                setGstIn(e.value.GSTIN ?? "");
                setAddress(e.value.address ?? "");
                setOldBalance(e.value.credit ?? "0.00");
                setId(e.value?.member_id ?? "");
              }}
              options={data}
              optionLabel="name_of_org"
              filter
              onFilter={(e) => {
                console.log("filt/essssr", e.filter);
                if (e.filter) {
                  setSearch(e.filter);
                  if (search.length > 3) {
                    searchFunction();
                  } else {
                    setData([{}]);
                  }
                }
              }}
              valueTemplate={selectedBankTemplate}
              itemTemplate={bankOptionTemplate}
            /> */}
             <Select
      styles={{
        control: (provided) => ({
          ...provided,
          width: "454px",
          border: "1px solid #000000",
          borderRadius: "0px",
          marginLeft: "2px",
          textAlign: "left",
          marginTop: "2px",
          height: "40px",
        }),
      }}
      id="member_name_of_org"
      value={compony}
      onChange={(selectedOption) => {
        setCompony(selectedOption);
        setGstIn(selectedOption?.GSTIN ?? "");
        setAddress(selectedOption?.address ?? "");
        setOldBalance(selectedOption?.credit ?? "0.00");
        setId(selectedOption?.member_id ?? "");
      }}
      options={data}
      isSearchable
      onInputChange={(inputValue) => {
        setSearch(inputValue);
        if (inputValue.length > 3) {
          searchFunction(inputValue);
        } else {
          setData([{}]);
        }
      }}
      placeholder="Search for an organization..."
      getOptionLabel={(option) => option.name_of_org}
      getOptionValue={(option) => option.member_id}
    />
          </div>
          <div className="flex align-items-center mt-2">
            <div
              className="text-l align-items-center"
              style={{
                paddingRight: "5px",
                marginTop: "2px",
                textAlign: "right",
                width: "200px",
              }}
            >
              Member Address
            </div>
            <InputText
              style={{
                width: "454px",
                border: "1px solid #000000",
                borderRadius: "0px",
                marginLeft: "2px",
                textAlign: "left",
                marginTop: "2px",
                height: "40px",
              }}
              id="member_address"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            ></InputText>
          </div>
          <div className="flex align-items-center mt-2">
            <div
              className="text-l align-items-center"
              style={{
                paddingRight: "5px",
                marginTop: "2px",
                textAlign: "right",
                width: "200px",
              }}
            >
              Gst No .
            </div>

            <InputText
              style={{
                width: "454px",
                border: "1px solid #000000",
                borderRadius: "0px",
                marginLeft: "2px",
                textAlign: "left",
                marginTop: "2px",
                height: "40px",
              }}
              id="member_gst_no"
              value={gstIn}
              onChange={(e) => {
                setGstIn(e.target.value);
              }}
            ></InputText>
          </div>
          <div
            style={{
              textAlign: "right",
              color: "red",
              fontSize: "20px",
              fontWeight: "bold",
              marginTop: "20px",
            }}
          >
            Balance RS. {oldBalance}
          </div>
          <div className="flex align-items-center mt-2">
            <div
              className="text-l align-items-center"
              style={{
                paddingRight: "5px",
                marginTop: "2px",
                textAlign: "right",
                width: "200px",
              }}
            >
              Credit Amount
            </div>
            <InputText
              style={{
                width: "454px",
                border: "1px solid #000000",
                borderRadius: "0px",
                marginLeft: "2px",
                textAlign: "left",
                marginTop: "2px",
                height: "40px",
              }}
              id="member_address"
              placeholder="Enter new amount"
              value={balance}
              onChange={(e) => {
                const inputValue = e.target.value;
    
                // Check if the input is a positive number or an empty string
                if (/^\d*\.?\d*$/.test(inputValue) || inputValue === "") {
                  setBalance(inputValue);
                }
              }}
            ></InputText>
          </div>
          <Button
            label="Save"
            loading={loading}
            style={{ marginTop: "30px" }}
            onClick={() => {
              addAmount();
            }}
            type="button"
          />
        </div>
      </Panel>
      <div style={{ display: "none" }}>
        <div ref={BalanceCreditRef}>
          <DownloadAmountReport data={creditData} />
        </div>
      </div>
    </div>
  );
  
};
